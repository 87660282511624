export const getRepeatOptions = (t) => {
  const repeatOptions = [t('days'), t('weeks'), t('months'), t('years'), ...getRepeatOtherOptions(t)];
  return repeatOptions;
};

export const getCustomDateList = (t) => {
  const customDateList = [
    { id: 0, val: t('S') },
    { id: 1, val: t('M') },
    { id: 2, val: t('T') },
    { id: 3, val: t('W') },
    { id: 4, val: t('T') },
    { id: 5, val: t('F') },
    { id: 6, val: t('S') },
  ];
  return customDateList;
};

export const getRepeatOtherOptions = (t) => {
  const repeatOptions = [
    t('first day of month'),
    t('last day of month'),
    t('Weekdays only'),
    t('Weekends only'),
    t('Custom'),
  ];
  return repeatOptions;
};

export const getPeriodOptions = (t) => {
  const periodOptions = [t('minutes'), t('hours'), t('days')];
  return periodOptions;
};

export const getEnabledOptions = (t) => {
  const periodOptions = [t('enabled'), t('disabled')];
  return periodOptions;
};

export const getTimePeriodOptions = (t) => {
  const periodOptions = [t('minutes'), t('hours'), t('days'), t('months')];
  return periodOptions;
};

export const getLabourTypeOptions = (t) => {
  const periodOptions = [
    t('Admin'),
    t('Air Conditioning'),
    t('Cleaner'),
    t('Electrical'),
    t('Handyman'),
    t('Mechanical'),
    t('Security'),
    t('Plumbing'),
  ];
  return periodOptions;
};

export const getFloorOptions = (t) => {
  const floorOptions = [
    { value: null, label: t('None') },
    { value: 1000, label: t('Roof') },
    { value: 200, label: t('Floor 200') },
    { value: 199, label: t('Floor 199') },
    { value: 198, label: t('Floor 198') },
    { value: 197, label: t('Floor 197') },
    { value: 196, label: t('Floor 196') },
    { value: 195, label: t('Floor 195') },
    { value: 194, label: t('Floor 194') },
    { value: 193, label: t('Floor 193') },
    { value: 192, label: t('Floor 192') },
    { value: 191, label: t('Floor 191') },
    { value: 190, label: t('Floor 190') },
    { value: 189, label: t('Floor 189') },
    { value: 188, label: t('Floor 188') },
    { value: 187, label: t('Floor 187') },
    { value: 186, label: t('Floor 186') },
    { value: 185, label: t('Floor 185') },
    { value: 184, label: t('Floor 184') },
    { value: 183, label: t('Floor 183') },
    { value: 182, label: t('Floor 182') },
    { value: 181, label: t('Floor 181') },
    { value: 180, label: t('Floor 180') },
    { value: 179, label: t('Floor 179') },
    { value: 178, label: t('Floor 178') },
    { value: 177, label: t('Floor 177') },
    { value: 176, label: t('Floor 176') },
    { value: 175, label: t('Floor 175') },
    { value: 174, label: t('Floor 174') },
    { value: 173, label: t('Floor 173') },
    { value: 172, label: t('Floor 172') },
    { value: 171, label: t('Floor 171') },
    { value: 170, label: t('Floor 170') },
    { value: 169, label: t('Floor 169') },
    { value: 168, label: t('Floor 168') },
    { value: 167, label: t('Floor 167') },
    { value: 166, label: t('Floor 166') },
    { value: 165, label: t('Floor 165') },
    { value: 164, label: t('Floor 164') },
    { value: 163, label: t('Floor 163') },
    { value: 162, label: t('Floor 162') },
    { value: 161, label: t('Floor 161') },
    { value: 160, label: t('Floor 160') },
    { value: 159, label: t('Floor 159') },
    { value: 158, label: t('Floor 158') },
    { value: 157, label: t('Floor 157') },
    { value: 156, label: t('Floor 156') },
    { value: 155, label: t('Floor 155') },
    { value: 154, label: t('Floor 154') },
    { value: 153, label: t('Floor 153') },
    { value: 152, label: t('Floor 152') },
    { value: 151, label: t('Floor 151') },
    { value: 150, label: t('Floor 150') },
    { value: 149, label: t('Floor 149') },
    { value: 148, label: t('Floor 148') },
    { value: 147, label: t('Floor 147') },
    { value: 146, label: t('Floor 146') },
    { value: 145, label: t('Floor 145') },
    { value: 144, label: t('Floor 144') },
    { value: 143, label: t('Floor 143') },
    { value: 142, label: t('Floor 142') },
    { value: 141, label: t('Floor 141') },
    { value: 140, label: t('Floor 140') },
    { value: 139, label: t('Floor 139') },
    { value: 138, label: t('Floor 138') },
    { value: 137, label: t('Floor 137') },
    { value: 136, label: t('Floor 136') },
    { value: 135, label: t('Floor 135') },
    { value: 134, label: t('Floor 134') },
    { value: 133, label: t('Floor 133') },
    { value: 132, label: t('Floor 132') },
    { value: 131, label: t('Floor 131') },
    { value: 130, label: t('Floor 130') },
    { value: 129, label: t('Floor 129') },
    { value: 128, label: t('Floor 128') },
    { value: 127, label: t('Floor 127') },
    { value: 126, label: t('Floor 126') },
    { value: 125, label: t('Floor 125') },
    { value: 124, label: t('Floor 124') },
    { value: 123, label: t('Floor 123') },
    { value: 122, label: t('Floor 122') },
    { value: 121, label: t('Floor 121') },
    { value: 120, label: t('Floor 120') },
    { value: 119, label: t('Floor 119') },
    { value: 118, label: t('Floor 118') },
    { value: 117, label: t('Floor 117') },
    { value: 116, label: t('Floor 116') },
    { value: 115, label: t('Floor 115') },
    { value: 114, label: t('Floor 114') },
    { value: 113, label: t('Floor 113') },
    { value: 112, label: t('Floor 112') },
    { value: 111, label: t('Floor 111') },
    { value: 110, label: t('Floor 110') },
    { value: 109, label: t('Floor 109') },
    { value: 108, label: t('Floor 108') },
    { value: 107, label: t('Floor 107') },
    { value: 106, label: t('Floor 106') },
    { value: 105, label: t('Floor 105') },
    { value: 104, label: t('Floor 104') },
    { value: 103, label: t('Floor 103') },
    { value: 102, label: t('Floor 102') },
    { value: 101, label: t('Floor 101') },
    { value: 100, label: t('Floor 100') },
    { value: 99, label: t('Floor 99') },
    { value: 98, label: t('Floor 98') },
    { value: 97, label: t('Floor 97') },
    { value: 96, label: t('Floor 96') },
    { value: 95, label: t('Floor 95') },
    { value: 94, label: t('Floor 94') },
    { value: 93, label: t('Floor 93') },
    { value: 92, label: t('Floor 92') },
    { value: 91, label: t('Floor 91') },
    { value: 90, label: t('Floor 90') },
    { value: 89, label: t('Floor 89') },
    { value: 88, label: t('Floor 88') },
    { value: 87, label: t('Floor 87') },
    { value: 86, label: t('Floor 86') },
    { value: 85, label: t('Floor 85') },
    { value: 84, label: t('Floor 84') },
    { value: 83, label: t('Floor 83') },
    { value: 82, label: t('Floor 82') },
    { value: 81, label: t('Floor 81') },
    { value: 80, label: t('Floor 80') },
    { value: 79, label: t('Floor 79') },
    { value: 78, label: t('Floor 78') },
    { value: 77, label: t('Floor 77') },
    { value: 76, label: t('Floor 76') },
    { value: 75, label: t('Floor 75') },
    { value: 74, label: t('Floor 74') },
    { value: 73, label: t('Floor 73') },
    { value: 72, label: t('Floor 72') },
    { value: 71, label: t('Floor 71') },
    { value: 70, label: t('Floor 70') },
    { value: 69, label: t('Floor 69') },
    { value: 68, label: t('Floor 68') },
    { value: 67, label: t('Floor 67') },
    { value: 66, label: t('Floor 66') },
    { value: 65, label: t('Floor 65') },
    { value: 64, label: t('Floor 64') },
    { value: 63, label: t('Floor 63') },
    { value: 62, label: t('Floor 62') },
    { value: 61, label: t('Floor 61') },
    { value: 60, label: t('Floor 60') },
    { value: 59, label: t('Floor 59') },
    { value: 58, label: t('Floor 58') },
    { value: 57, label: t('Floor 57') },
    { value: 56, label: t('Floor 56') },
    { value: 55, label: t('Floor 55') },
    { value: 54, label: t('Floor 54') },
    { value: 53, label: t('Floor 53') },
    { value: 52, label: t('Floor 52') },
    { value: 51, label: t('Floor 51') },
    { value: 50, label: t('Floor 50') },
    { value: 49, label: t('Floor 49') },
    { value: 48, label: t('Floor 48') },
    { value: 47, label: t('Floor 47') },
    { value: 46, label: t('Floor 46') },
    { value: 45, label: t('Floor 45') },
    { value: 44, label: t('Floor 44') },
    { value: 43, label: t('Floor 43') },
    { value: 42, label: t('Floor 42') },
    { value: 41, label: t('Floor 41') },
    { value: 40, label: t('Floor 40') },
    { value: 39, label: t('Floor 39') },
    { value: 38, label: t('Floor 38') },
    { value: 37, label: t('Floor 37') },
    { value: 36, label: t('Floor 36') },
    { value: 35, label: t('Floor 35') },
    { value: 34, label: t('Floor 34') },
    { value: 33, label: t('Floor 33') },
    { value: 32, label: t('Floor 32') },
    { value: 31, label: t('Floor 31') },
    { value: 30, label: t('Floor 30') },
    { value: 29, label: t('Floor 29') },
    { value: 28, label: t('Floor 28') },
    { value: 27, label: t('Floor 27') },
    { value: 26, label: t('Floor 26') },
    { value: 25, label: t('Floor 25') },
    { value: 24, label: t('Floor 24') },
    { value: 23, label: t('Floor 23') },
    { value: 22, label: t('Floor 22') },
    { value: 21, label: t('Floor 21') },
    { value: 20, label: t('Floor 20') },
    { value: 19, label: t('Floor 19') },
    { value: 18, label: t('Floor 18') },
    { value: 17, label: t('Floor 17') },
    { value: 16, label: t('Floor 16') },
    { value: 15, label: t('Floor 15') },
    { value: 14, label: t('Floor 14') },
    { value: 13, label: t('Floor 13') },
    { value: 12, label: t('Floor 12') },
    { value: 11, label: t('Floor 11') },
    { value: 10, label: t('Floor 10') },
    { value: 9, label: t('Floor 9') },
    { value: 8, label: t('Floor 8') },
    { value: 7, label: t('Floor 7') },
    { value: 6, label: t('Floor 6') },
    { value: 5, label: t('Floor 5') },
    { value: 4, label: t('Floor 4') },
    { value: 3, label: t('Floor 3') },
    { value: 2, label: t('Floor 2') },
    { value: 1, label: t('Floor 1') },
    { value: 0, label: t('Ground') },
    { value: -1, label: t('Floor -1') },
    { value: -2, label: t('Floor -2') },
    { value: -3, label: t('Floor -3') },
    { value: -4, label: t('Floor -4') },
    { value: -5, label: t('Floor -5') },
    { value: -6, label: t('Floor -6') },
    { value: -7, label: t('Floor -7') },
    { value: -8, label: t('Floor -8') },
    { value: -9, label: t('Floor -9') },
    { value: -10, label: t('Floor -10') },
  ];
  return floorOptions;
};

export const getConditionOptions = (t) => {
  const ConditionOptions = [t('Excellent'), t('Good'), t('Poor')];
  return ConditionOptions;
};

export const getCountyOptions = (t) => {
  const CountyOptions = [
    t('Aberdeen, City of'),
    t('Aberdeenshire'),
    t('Angus (Forfarshire)'),
    t('Antrim'),
    t('Argyll'),
    t('Armagh'),
    t('Avon'),
    t('Ayrshire'),
    t('Banffshire'),
    t('Bedfordshire'),
    t('Belfast, City of'),
    t('Berwickshire'),
    t('Berkshire'),
    t('Bristol, City of'),
    t('Buckinghamshire'),
    t('Bute'),
    t('Caithness'),
    t('Cambridgeshire'),
    t('Cheshire'),
    t('Clackmannanshire'),
    t('Cleveland'),
    t('Clwyd'),
    t('Cornwall'),
    t('Cromartyshire'),
    t('Cumbria'),
    t('Derbyshire'),
    t('Derry, City of'),
    t('Devon'),
    t('Dorset'),
    t('Down'),
    t('Dumfriesshire'),
    t('Dunbartonshire (Dumbarton)'),
    t('Dundee, City of'),
    t('Durham'),
    t('Dyfed'),
    t('East Lothian (Haddingtonshire)'),
    t('East Sussex'),
    t('Edinburgh, City of'),
    t('Essex'),
    t('Fermanagh'),
    t('Fife'),
    t('Glasgow, City of'),
    t('Gloucestershire'),
    t('Greater London'),
    t('Greater Manchester'),
    t('Gwent'),
    t('Gwynedd'),
    t('Hampshire (County of Southampton)'),
    t('Hereford and Worcester'),
    t('Herefordshire'),
    t('Hertfordshire'),
    t('Inverness-shire'),
    t('Isle of Wight'),
    t('Kent'),
    t('Kincardineshire'),
    t('Kinross-shire'),
    t('Kirkcudbrightshire'),
    t('Lanarkshire'),
    t('Lancashire'),
    t('Leicestershire'),
    t('Lincolnshire'),
    t('London'),
    t('Londonderry'),
    t('Merseyside'),
    t('Middlesex'),
    t('Mid Glamorgan'),
    t('Midlothian (County of Edinburgh)'),
    t('Moray (Elginshire)'),
    t('Nairnshire'),
    t('Norfolk'),
    t('Northamptonshire'),
    t('Northumberland'),
    t('North Humberside'),
    t('North Yorkshire'),
    t('Nottinghamshire'),
    t('Orkney'),
    t('Oxfordshire'),
    t('Peeblesshire'),
    t('Perthshire'),
    t('Powys'),
    t('Renfrewshire'),
    t('Ross and Cromarty'),
    t('Ross-shire'),
    t('Roxburghshire'),
    t('Rutland'),
    t('Selkirkshire'),
    t('Shetland (Zetland)'),
    t('Shropshire'),
    t('Somerset'),
    t('South Glamorgan'),
    t('South Humberside'),
    t('South Yorkshire'),
    t('Staffordshire'),
    t('Stirlingshire'),
    t('Suffolk'),
    t('Surrey'),
    t('Sutherland'),
    t('Tyne and Wear'),
    t('Tyrone'),
    t('Warwickshire'),
    t('West Glamorgan'),
    t('West Lothian (Linlithgowshire)'),
    t('West Midlands'),
    t('West Sussex'),
    t('West Yorkshire'),
    t('Wigtownshire'),
    t('Wiltshire'),
    t('Worcestershire'),
  ];
  return CountyOptions;
};

export const getSiteTypeOptions = (t) => {
  const siteTypeOptions = [
    { value: 1, label: t('Commercial') },
    { value: 2, label: t('Residential') },
  ];
  return siteTypeOptions;
};

export const getStatusColorClassOptions = (t) => {
  const StatusColorClassOptions = [
    t('wallchart-grey'),
    t('wallchart-amber'),
    t('wallchart-blue'),
    t('wallchart-green'),
    t('wallchart-red'),
  ];
  return StatusColorClassOptions;
};

export const getDeliveryTypeOptions = () => {
  const data = [
    { id: 1, value: 'Parcel' },
    { id: 2, value: 'Letter' },
    { id: 3, value: 'Food' },
    { id: 4, value: 'Flowers' },
    { id: 6, value: 'Drinks' },
    { id: 7, value: 'IT Equipment' },
  ];
  return { list: data, objList: arrayToObjKeyValue(data, 'id', 'value') };
};

export const getDeliverySizeOptions = () => {
  const data = [
    { id: 1, value: 'Small' },
    { id: 2, value: 'Medium' },
    { id: 3, value: 'Large' },
    { id: 4, value: 'Extra Large' },
    { id: 5, value: 'Pallet' },
  ];
  return { list: data, objList: arrayToObjKeyValue(data, 'id', 'value') };
};

export const arrayToObjKeyValue = (param, key, value) => {
  let obj = {};
  for (let index = 0; index < param.length; index++) {
    const e = param[index];
    obj[e[key]] = e[value];
  }
  return obj;
};

export const getEmailTemplateOptions = () => {
  const data = [
    {
      id: 1,
      value: 'Document Expiry Email Template',
      description: [
        { variable: '{docList}', desc: 'It generates a table of expiring documents and includes links to them.' },
      ],
      type: 'SYSTEM',
    },
    {
      id: 2,
      value: 'Job Create Email Template',
      description: [
        { variable: '{%job.id%}', desc: 'Job Id.' },
        { variable: '{%job.subject%}', desc: 'Job Subject.' },
        { variable: '{%job.description%}', desc: 'Job description.' },
        { variable: '{%job.createdAt%}', desc: 'Job createdAt.' },
        { variable: '{%job.visitDate%}', desc: 'Job visitDate.' },
        { variable: '{%contact.firstNamet%}', desc: 'Contact name.' },
        { variable: '{%job.assignedTo%}', desc: 'Job assignedTo.' },
      ],
      type: 'SYSTEM',
    },
    {
      id: 3,
      value: 'Job Time Update Email Template',
      description: [
        { variable: '{%job.id%}', desc: 'Job Id.' },
        { variable: '{%job.subject%}', desc: 'Job Subject.' },
        { variable: '{%job.description%}', desc: 'Job description.' },
        { variable: '{%job.createdAt%}', desc: 'Job createdAt.' },
        { variable: '{%job.visitDate%}', desc: 'Job visitDate.' },
        { variable: '{%contact.firstNamet%}', desc: 'Contact name.' },
        { variable: '{%job.comment%}', desc: 'Job comment.' },
      ],
      type: 'SYSTEM',
    },
    {
      id: 4,
      value: 'Retention Reminder Email Template',
      description: [],
      type: 'SYSTEM',
    },
    {
      id: 5,
      value: 'Email Template',
      description: [],
      type: 'others',
    },
  ];
  return {
    list: data,
    objList: arrayToObjKeyValue(data, 'id', 'value'),
    objTypeList: arrayToObjKeyValue(data, 'id', 'type'),
    descriptions: arrayToObjKeyValue(data, 'id', 'description'),
  };
};

export const getCompanyTypeOptions = () => {
  const data = [
    { id: 1, value: 'Customer' },
    { id: 2, value: 'Supplier' },
    { id: 3, value: 'Customer and Supplier' },
  ];
  return data;
};

export const getPriorityOptions = () => {
  const data = [
    { id: 1, value: 'Low' },
    { id: 2, value: 'Medium' },
    { id: 3, value: 'High' },
  ];
  return { list: data, objList: arrayToObjKeyValue(data, 'id', 'value') };
};

export const getShareLevelOptions = () => {
  const data = [
    { id: 1, value: 'Site' },
    { id: 2, value: 'Individual' },
    { id: 3, value: 'Role' },
  ];
  return { list: data, objList: arrayToObjKeyValue(data, 'id', 'value') };
};

export const EMAIL_TEMPLATE_FIELD_LIST = [
  { id: 1, field: 'job.id', value: 'Job Id', tooltip: 'Shows Job Id', fieldType: 'string' },
  { id: 2, field: 'job.dueDate', value: 'Due Date', tooltip: 'Shows Due Date', fieldType: 'date' },
  { id: 3, field: 'job.visitDate', value: 'Visit Date', tooltip: 'Shows Visit Date', fieldType: 'date' },
  { id: 4, field: 'job.closedDate', value: 'Closed Date', tooltip: 'Shows Closed Date', fieldType: 'date' },
  {
    id: 5,
    field: 'job.description',
    value: 'Job Description',
    tooltip: 'Shows Job Description',
    fieldType: 'string',
  },
  {
    id: 6,
    field: 'job.subject',
    value: 'Job Subject',
    tooltip: 'Shows Subject',
    fieldType: 'string',
  },
  {
    id: 7,
    field: 'job.Site.name',
    value: 'Site name',
    tooltip: 'Shows site name',
    fieldType: 'string',
  },
  {
    id: 8,
    field: 'job.UserAssignee.fullName',
    value: 'User Assignee Name',
    tooltip: 'Shows User Assignee Name ',
    fieldType: 'string',
  },

  {
    id: 9,
    field: 'job.Location.name',
    value: 'Job Location',
    tooltip: 'Shows Job Location',
    fieldType: 'string',
  },
  {
    id: 10,
    field: 'job.Status.name',
    value: 'Job Status',
    tooltip: 'Shows Job Status',
    fieldType: 'string',
  },
];

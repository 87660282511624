import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';

import { SelectDropdown } from '../form/parts';

import { getAuditLogList, getEmailDeliveryReport } from '../../actions/audit/Log';
import { shortName, titleCase } from '../../helpers/customHelper';

class NewActivityView extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired,
    activityLogs: PropTypes.arrayOf(PropTypes.shape()),
    getAuditLogList: PropTypes.func.isRequired,
    rowId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    showActivitySelection: PropTypes.bool.isRequired,
    parentAssociatedId: PropTypes.number,
    profileLinkPermissions: PropTypes.shape(),
    t: PropTypes.func,
  };

  static defaultProps = {
    activityLogs: [],
    parentAssociatedId: 0,
    profileLinkPermissions: {},
  };

  constructor(props) {
    super(props);

    const { rowId, entityType, parentAssociatedId } = props;
    this.state = {
      activityFor: {
        value: 'all',
        label: 'Show All',
      },
      emailModal: false,
      logId: 0,
    };
    this.props.getAuditLogList({
      rowId,
      entityType,
      showForSpecificEntity: false,
      parentAssociatedId,
    });
  }

  getMessageDeliveryReport = (id) => {
    this.setState({ emailModal: true, logId: id });
    this.props.getEmailDeliveryReport(id);
  };

  onChangeActivityDropdown = (key) => {
    const { rowId, entityType, parentAssociatedId } = this.props;
    this.setState({
      activityFor: {
        value: key.value,
        label: key.label,
      },
    });
    if (key.value === 'all') {
      // means activity of master with all child activity
      this.props.getAuditLogList({
        rowId,
        entityType,
        showForSpecificEntity: false,
        parentAssociatedId,
      });
    } else {
      // means masters > specific child activity
      this.props.getAuditLogList({
        rowId,
        entityType: key.value, // key,
        showForSpecificEntity: true,
        parentAssociatedId,
      });
    }
  };

  render() {
    const { activityLogs, showActivitySelection, entityType, profileLinkPermissions, t, deliveryList } = this.props;
    const { activityFor } = this.state;

    return (
      <div className="container-fluid no-padding-h zero-side-paddings" id="viewReportDetails">
        <div className="wrap-for-max-width">
          <div className="col-lg-12">
            {activityLogs &&
              activityLogs.map((data, i) => (
                <div key={i} className="activity-list mb-4">
                  <div className="headline-block justify-content-between">
                    <div className="flex flex-wrap">
                      <div className="sort-user-name mr-2">{shortName(data?.CreatedBy?.fullName)}</div>
                      <p className="mb-0 align-self-center">
                        <strong>{titleCase(data?.CreatedBy?.fullName)}</strong>
                        <span> &nbsp; updated the job at &nbsp;</span>
                        <strong>{moment(data?.createdAt).format('DD MMM YYYY HH:mm:ss')}</strong>
                      </p>
                    </div>
                  </div>
                  <div className="ml-7 flex flex-column pl-2">
                    <div className="field-name mb-2" style={{ fontWeight: 'bold' }}>
                      {data.description}
                    </div>
                    {data.LogDetail.length > 0 &&
                      data.LogDetail.map((e, j) => (
                        <div className="flex flex-column w-auto mb-2">
                          <div key={j} className="flex flex-wrap align-items-center mb-2">
                            <div className="field-name mr-2" style={{ fontWeight: 'bold' }}>
                              {e.fieldName}
                            </div>
                            <div className="flex flex-wrap align-items-center">
                              <div className="old-value">
                                {e.oldValue && moment(e.oldValue).isValid()
                                  ? moment(e.oldValue).format('YYYY-MM-DD HH:mm')
                                  : e.oldValue || 'empty'}
                              </div>
                              <div className=" ml-2 mr-2">
                                <i className="pi pi-arrow-right change-arrow"></i>
                              </div>
                              <div className="new-value">
                                {' '}
                                {e.newValue && moment(e.newValue).isValid()
                                  ? moment(e.newValue).format('YYYY-MM-DD HH:mm')
                                  : e.newValue || 'empty'}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    tokens: { accessToken },
    auditLogs: { list, count, deliveryList },
    user,
  } = state;
  return {
    accessToken,
    activityLogs: list,
    deliveryList,
    count,
    userPermissions: user.currentuserpermissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAuditLogList,
      getEmailDeliveryReport,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: 'activityForm',
  })(NewActivityView),
);

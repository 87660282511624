import {
  LOAD_PPM_JOB,
  LOAD_PPM_JOBS,
  LOAD_ALL_PPM_JOBS,
  LOAD_PPM_JOB_COUNT,
  LOAD_DASHBOARD_JOBS,
} from '../../actions/types';

const INITIAL_STATE = {
  item: null,
  count: null,
  all: [],
  list: [],
  dashboardJobs: {},
};

const ppmJob = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_PPM_JOB: {
      const item = action.payload;
      let { list, all, count } = state;
      if (item.statusName !== null) {
        list = list.map((listItem) => (item.id === listItem.id ? item : listItem));
        all = all.map((listItem) => (item.id === listItem.id ? item : listItem));
      } else {
        list = list.filter((listItem) => item.id !== listItem.id);
        all = all.filter((listItem) => item.id !== listItem.id);
        count -= 1;
      }
      return {
        item,
        list,
        all,
        count,
      };
    }
    case LOAD_PPM_JOBS:
      return { ...state, list: action.payload };
    case LOAD_ALL_PPM_JOBS:
      return { ...state, all: action.payload };
    case LOAD_PPM_JOB_COUNT:
      return { ...state, count: action.payload };
    case LOAD_DASHBOARD_JOBS:
      return { ...state, dashboardJobs: { ...state.dashboardJobs, ...action.payload } };
    default:
      return state;
  }
};

export default ppmJob;

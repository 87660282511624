import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { CascadeSelect } from 'primereact/cascadeselect';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete } from 'primereact/autocomplete';
import { FilterMatchMode } from 'primereact/api';
import { InputNumber } from 'primereact/inputnumber';
import { Ripple } from 'primereact/ripple';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import _, { isArray } from 'lodash';
import moment from 'moment';
import { useLocalStorage } from 'primereact/hooks';
import { Tooltip } from 'react-tooltip';

import { CustomFilter, SmartFilter } from '../../components/filter';
import { AddNewItemView, TitleView, AddInModalViewSmall } from '../../components/decorate';

import userSelectedPreference from '../../lib/userSelectedPreference';
import PAGE_SIZE from '../../constants/tabledata/page_size';
import IntableMenu from '../../components/table/IntableMenu';

import DataTableExport from './DataTableExport';
import AddMoreColumnOptionsButton from './AddMoreColumnOptionsButton';
import TableActionMenu from '../../components/decorate/TableActionMenu';
import { CustomTextBox } from '../../components/filter/CustomFilter';

const Options = [
  { label: 'Equal', value: '=' },
  { label: 'Less than or equal', value: '<=' },
  { label: 'Greater than or equal', value: '>=' },
  { label: 'Greater', value: '>' },
  { label: 'Less', value: '<' },
  { label: 'Like', value: 'like' },
];

const BooleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const SmartDataTable = ({
  list = [],
  count = 0,
  addNewItem = {},
  deliveryBtn = {},
  budgetPeriodFilter = false,
  boolTypes = null,
  typeId = null,
  useUuid = false,
  funcs = [],
  extraParams = {},
  navParams = {},
  filterValues = {},
  filterData = {},
  orderField = 'id',
  taskNavParams = [],
  categoryGroupFilter = false,
  categoryGroups = [],
  navigateOnClick = () => {},
  showAddNew = true,
  title = null,
  extraHeaderButtons = [],
  showQuickAddNew = false,
  quickAddNewItem = {},
  multiDeleteMode = false,
  multiEditReactiveConfig = false,
  multiArchiveMode = false,
  handleDeleteMultiJob = () => {},
  handleDuplicateMultiJob = () => {},
  handleMultipleEditJob = () => {},
  hamdelMultiEditReactiveConfig = () => {},
  handleArchiveMultiJob = () => {},
  showStartEndDate = false,
  startEndDate = {},
  tableId = '',
  headers,
  intableMenu = [],
  paramsWithFilter: paramsWithFilterProps,
  filterParams: filterParamsProps,
  user,
  getListWithSize,
  t,
  loading = false,
  url,
  store,
  advanceFilterData,
  isCustomFilter = false,
  customFilterList = [],
  customFilterValues = {},
  columnMoreoptions = [],
  exportData,
  isCustomActions = false,
  isTopDuplicate = true,
  isTopEdit = true,
  rowClassNameFn = () => {},
  showActionColumn = true,
  isColumnFilter = true,
  customSearchFilter = false,
  otherOptions = [],
  pageSize = PAGE_SIZE,
  hideHeader = false,
  activeFilterParams = null,
  columnConfig = null,
  isActiveDoubleClick = true,
  selectionMode = 'checkbox',
  searchData = {
    type: 'defaultSearch',
    name: 'Search Jobs',
    placeholder: 'Search Jobs',
    filterField: 'all',
    isDefault: true,
  },
  isDataSelectable = () => true,
}) => {
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState(1);
  const [first, setFirst] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filters, setFilters] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [activeFilter, setActiveFilter] = useState([]);
  const [filterParams, setFilterParams] = useState(null);
  const [paramsWithFilter, setParamsWithFilter] = useState({});
  const [filterContitionParams, setFilterContitionParams] = useState([]);
  const [page, setPage] = useState();
  const [numberCondition, setNumberCondition] = useState('=');
  const [filterEnable, setFilterEnable] = useState(false);
  const [formattedList, setFormattedList] = useState([]);
  const [showRollBack, setShowRollBack] = useState(false);
  const [tableInitiated, setTableInitiated] = useState(false);
  const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
  const [isFilterAvailable, setIsFilterAvailable] = useState(false);
  const [multipleColumns, setMultipleColumns] = useLocalStorage(headers, 'multiColValues-' + tableId);
  const isFirstRun = useRef(true);
  const setDefaultTableColumns = () => {
    setMultipleColumns(headers);
  };

  const rawParam = {
    page: 0,
    pageSize: pageSize,
    orderField: orderField,
    orderType: 'DESC',
  };

  useEffect(() => {
    if (isFirstRun.current && headers.length > 0 && multipleColumns.length > 0) {
      const allHeaders = [...headers, ...columnMoreoptions];
      const headerLabels = allHeaders.map((header) => header.label);
      const headerNames = allHeaders.map((header) => header.name);
      const ifallFromHeader = multipleColumns.every((column) => headerLabels.includes(column.label));
      if (!ifallFromHeader) {
        isFirstRun.current = false;
        let newColumndata = multipleColumns.filter((column) => headerNames.includes(column.name));
        newColumndata = multipleColumns.map((column) => {
          if (!headerLabels.includes(column.label)) {
            column.label = allHeaders[headerNames.indexOf(column.name)].label;
          }
          return column;
        });
        setMultipleColumns(newColumndata);
      }
    }
  }, [headers, multipleColumns, setMultipleColumns]);

  useEffect(() => {
    const defaultParams = {
      page: 0,
      pageSize: pageSize,
      orderField: orderField,
      orderType: 'DESC',
    };

    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }

    const tempPagingData = userSelectedPreference.getSpecificTablePagingData({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
    });

    const activeFilters = userSelectedPreference.getSpecificTableActiveFilterData({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
    });

    setActiveFilter(activeFilters || []);

    if (tempPagingData && tempPagingData.pagingInfo) {
      defaultParams.page = tempPagingData.pagingInfo.page;
      defaultParams.pageSize =
        tempPagingData.pagingInfo.pageSize == undefined ? defaultParams.pageSize : tempPagingData.pagingInfo.pageSize;
      defaultParams.orderField = tempPagingData.pagingInfo.orderField;
      defaultParams.orderType = tempPagingData.pagingInfo.orderType;
      if (tempPagingData.pagingInfo.searchTerm && tempPagingData.pagingInfo.searchTerm !== '') {
        defaultParams.searchTerm = tempPagingData.pagingInfo.searchTerm;
      }
    }
    const tempFilterConditionParam =
      tempPagingData && tempPagingData.hasOwnProperty('pagingInfo')
        ? tempPagingData.pagingInfo.hasOwnProperty('filters')
          ? tempPagingData.pagingInfo.filters
          : []
        : extraParams.filters || [];

    const tempParams = {
      ...defaultParams,
      ...extraParams,
      filters: tempFilterConditionParam,
    };

    setFilterContitionParams(tempFilterConditionParam);
    const tempFilterParam = tempPagingData && tempPagingData.filterParams ? tempPagingData.filterParams : null;
    setFilterParams(tempFilterParam == null ? {} : tempFilterParam);
    setFilterEnable(Object.keys(filterData || {})?.length > 0 && tempFilterConditionParam?.length > 0);
    setShowAdvancedFilter(Object.keys(advanceFilterData || {})?.length > 0 && tempFilterConditionParam?.length > 0);

    setParamsWithFilter(tempParams);

    setSortField(tempPagingData?.pagingInfo?.orderField || orderField);
    setSortOrder(tempPagingData?.pagingInfo?.orderType || 'ASC');
    const page =
      tempPagingData && tempPagingData.pagingInfo && tempPagingData.pagingInfo.page
        ? tempPagingData.pagingInfo.page
        : 0;

    setPage(page);
    setFirst(page > 0 ? page * defaultParams.pageSize : 0);

    if (filterData != undefined && Object.keys(filterData).length > 0) {
      setIsFilterAvailable(true);
    }
  }, []);

  useEffect(() => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    const tempPagingData = userSelectedPreference.getSpecificTablePagingData({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
    });
    const tempFilterConditionParam =
      tempPagingData && tempPagingData.hasOwnProperty('pagingInfo')
        ? tempPagingData.pagingInfo.hasOwnProperty('filters')
          ? tempPagingData.pagingInfo.filters
          : []
        : [];
    if (
      ((Object.values(filterValues).length > 0 && Object.values(filterValues)[0].length != 0) ||
        Object.values(filterValues).length === 0) &&
      tableInitiated == false
    ) {
      setTableInitiated(true);
      initFilter({ filters: tempFilterConditionParam });
    }
  }, [filterValues, filterParams]);

  const initFilter = (tempFilterParam) => {
    const filterType = {};
    Object.values(filterData).forEach((f) => {
      const filterElement = tempFilterParam.filters.find((i) => i.filterField == f.value);
      if (filterElement != undefined && tempFilterParam.filters.length > 0) {
        if (f.type === 'date' || f.type === 'number') {
          filterType[f.headerName] = {
            value: { value: filterElement.filterValue, condition: filterElement.fieldComparisonType },
            matchMode: FilterMatchMode.EQUALS,
          };
        } else if (f.type === 'string' && f.hasOwnProperty('array')) {
          if (f.hasOwnProperty('nestedArray')) {
            const selectedArr = filterValues[f.array].find((e) => {
              if (e[f.nestedArray].findIndex((t) => t.id === filterElement.filterValue) != -1) {
                return true;
              }
            });
            const { name, id } = selectedArr[f.nestedArray].find((t) => t.id === filterElement.filterValue);
            filterType[f.headerName] = { value: { name, id }, matchMode: FilterMatchMode.EQUALS };
          } else {
            const { name, id } = filterValues[f.array].find((e) => e.id === filterElement.filterValue);
            filterType[f.headerName] = { value: { name, id }, matchMode: FilterMatchMode.EQUALS };
          }
        } else if (f.type === 'groupDropDown') {
          let selectedArr = {};
          Object.values(filterValues[f.array]).find((e) => {
            const key = e.items.findIndex((t) => t.value === filterElement.filterValue);
            if (key !== -1) {
              selectedArr = e.items[key];
              filterType[f.headerName] = { value: selectedArr.value, matchMode: FilterMatchMode.EQUALS };
            }
          });
        } else {
          filterType[f.headerName] = { value: filterElement.filterValue, matchMode: FilterMatchMode.EQUALS };
        }
      } else {
        if (f.hasOwnProperty('headerName')) {
          filterType[f.headerName] = { value: null, matchMode: FilterMatchMode.EQUALS };
        }
      }
    });
    setFilters(filterType);
  };

  useEffect(() => {
    if (Object.keys(paramsWithFilter).length !== 0 && getListWithSize) {
      if (activeFilterParams && activeFilterParams.length > 0) {
        paramsWithFilter.filters = activeFilterParams;
      }
      getListWithSize(paramsWithFilter, t);
    }
  }, [paramsWithFilter]);

  useEffect(() => {
    const modifiedList = list.map((a) => {
      Object.keys(a).forEach(function (key) {
        if (a[key] === null) {
          a[key] = '-';
        }
      });
      return a;
    });
    setFormattedList(modifiedList);
    setSelectedItems([]);
  }, [list]);

  const onSort = (data) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }

    setSortField(data.sortField);
    setSortOrder(data.sortOrder);
    const filterParams = {
      ...paramsWithFilter,
      orderField: data.sortField,
      orderType: data.sortOrder === -1 ? 'ASC' : 'DESC',
    };
    setParamsWithFilter(filterParams);

    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: {
          ...filterParams,
          filters: filterContitionParams,
          page: 0,
        },
      },
    });
  };

  const Menu = (data) => {
    return (
      <IntableMenu
        firstOrLastRow={data.id === 0 || data.id === list.length - 1}
        id={useUuid ? data.uuid : data.id}
        typeId={data.typeId || typeId}
        intableMenu={data.intableMenu ? data.intableMenu : intableMenu}
        funcs={data.intableFuncs ? data.intableFuncs : funcs}
        navParams={navParams}
        taskParam={taskNavParams[data.id] || ''}
        t={t}
        row={data}
        paramsWithFilter={paramsWithFilter}
      />
    );
  };

  const onFilterDebounced = _.debounce((e) => {
    onFilter(e);
  }, 1000);

  const saveFilters = (tempFilterParam) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    let tempFilterConditions = [...filterContitionParams];
    const existingCondition = tempFilterConditions.findIndex((i) => i.filterField === tempFilterParam.filterField);
    if (existingCondition === -1) {
      tempFilterConditions = [...tempFilterConditions, ...[tempFilterParam]];
    } else {
      tempFilterConditions[existingCondition] = tempFilterParam;
    }
    setPage(0);
    setFirst(0);
    setFilterContitionParams([...tempFilterConditions]);
    setParamsWithFilter({
      ...extraParams,
      ...paramsWithFilter,
      filters: tempFilterConditions,
      page: 0,
    });
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: {
          ...paramsWithFilter,
          filters: tempFilterConditions,
          page: 0,
        },
      },
    });
  };

  const onFilter = (e) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    const filterConditions = Object.values(filterData).find((i) => i.headerName === e.target.name);
    if ((e.target.value != '' && e.target.value != null) || e.target.value === false) {
      const tempFilterParam = {
        fieldComparisonType:
          typeof e.target.value === 'number'
            ? numberCondition
            : filterConditions.type == 'string' || filterConditions.type == 'boolean'
            ? '='
            : filterConditions.type,
        filterField: filterConditions.value,
        filterValue:
          typeof e.target.value === 'string' || typeof e.target.value === 'number' || filterConditions.type == 'boolean'
            ? e.target.value
            : e.target.value.name,
      };
      saveFilters(tempFilterParam);
    } else {
      const params = { ...paramsWithFilter };
      let tempFilterConditions = [...filterContitionParams];
      const existingCondition = tempFilterConditions.findIndex((i) => i.filterField === filterConditions.value);
      if (existingCondition !== -1) {
        tempFilterConditions.splice(existingCondition, 1);
      }
      setPage(0);
      setFirst(0);
      setFilterContitionParams([...tempFilterConditions]);
      params.filters = [...tempFilterConditions];

      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: { ...params, page: 0 },
        },
      });
      setParamsWithFilter(params);
    }
  };

  const onFilterByDate = (e, headerItem) => {
    if (e.hasOwnProperty('condition') && e.hasOwnProperty('value')) {
      const tempFilterParam = {
        fieldComparisonType: e.condition,
        filterField: headerItem.value,
        filterValue: moment(e.value).format('YYYY-MM-DD'),
      };
      saveFilters(tempFilterParam);
    }
  };

  const onFilterByNumber = (e, headerItem) => {
    if (e.hasOwnProperty('condition') && e.hasOwnProperty('value')) {
      const tempFilterParam = {
        fieldComparisonType: e.condition,
        filterField: headerItem.value,
        filterValue: e.value,
      };
      saveFilters(tempFilterParam);
    }
  };

  const onFilterByOptions = (value, headerItem) => {
    const tempFilterParam = {
      fieldComparisonType: '=',
      filterField: headerItem.value,
      filterValue: value.id,
    };
    saveFilters(tempFilterParam);
  };

  const clearFilter = (e) => {
    let currentUrl;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    const tempFilterConditions = [...filterContitionParams];
    if (e != undefined) {
      const column = Object.values(filterData).find((f) => f.headerName === e);
      const existingCondition = tempFilterConditions.findIndex((i) => i.filterField === column.value);
      if (existingCondition !== -1) {
        tempFilterConditions.splice(existingCondition, 1);
      }
      setFilterContitionParams(tempFilterConditions);
    }
    setPage(0);
    setFirst(0);
    const params = { ...paramsWithFilter };
    params.filters = [...tempFilterConditions];
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: params,
        filterParams: {},
        filters: tempFilterConditions,
      },
    });
    setParamsWithFilter({ ...params });
  };

  const autoComplete = (event) => {
    const headerItem = Object.values(filterData).find((e) => e.headerName == event.originalEvent.target.name);
    const filterArray = filterValues[headerItem.array];
    if (filterArray !== undefined) {
      const formattedArray = filterArray
        .map((e) => ({ name: e.name, id: e.id }))
        .filter((e) => e.name.toLowerCase().indexOf(event.originalEvent.target.value.toLowerCase()) > -1);
      setSuggestions(formattedArray);
    } else {
      setSuggestions([]);
    }
  };

  const getDropDownOptions = (data) => {
    const filterArray = filterValues[data.array];
    return filterArray;
  };

  const onPageChange = (event) => {
    setParamsWithFilter({
      ...paramsWithFilter,
      page: event?.page,
    });
    setFirst(event.first);

    let currentUrl;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    userSelectedPreference.setUserSelectedPreference({
      currentPageUrl: currentUrl.asPath,
      userId: user && user.id,
      tableId,
      tablePagingPreference: {
        pagingInfo: {
          ...paramsWithFilter,
          page: event?.page,
        },
      },
    });
  };

  const onIncludeAll = (bool) => {
    setSelectedItems([]);
    setShowRollBack(bool);
    setParamsWithFilter({
      ...paramsWithFilter,
      includeAll: bool,
      page: 0,
    });
  };

  const setCustomFilter = (e) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }

    const activeFiltersList = e ? Object.values(e?.activeFilters || {}) : null;
    if (e?.addNew) {
      if (activeFiltersList) {
        setActiveFilter(activeFiltersList);
      }
      const params = { ...paramsWithFilter };
      const filterFieldList = activeFiltersList.map((i) => i.filterField);
      let tempFilterConditions = [...filterContitionParams].filter((i) => filterFieldList.includes(i.filterField));
      setPage(0);
      setFirst(0);
      setFilterContitionParams([...tempFilterConditions]);
      params.filters = [...tempFilterConditions];

      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: { ...params, page: 0 },
        },
        ...(activeFiltersList ? { activeFilters: activeFiltersList } : {}),
      });
      setParamsWithFilter(params);
    } else if (e?.removeAll) {
      if (activeFiltersList) {
        setActiveFilter(activeFiltersList);
      }
      const params = { ...paramsWithFilter, ...rawParam };
      params.filters = extraParams.filters && extraParams.filters.length > 0 ? extraParams.filters : [];
      !activeFiltersList && setActiveFilter(activeFilter);
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: { ...params, page: 0 },
        },
        activeFilters: activeFiltersList || activeFilter,
      });
      setFilterContitionParams([]);
      setParamsWithFilter(params);
    } else if (e.removeFilter) {
      if (activeFiltersList.length > 0) {
        setActiveFilter(activeFiltersList);
      }
      const params = { ...paramsWithFilter };
      let tempFilterConditions = [...filterContitionParams];
      const existingCondition = tempFilterConditions.findIndex((i) => i.filterField === e.filterField);
      if (existingCondition !== -1) {
        tempFilterConditions.splice(existingCondition, 1);
      }
      setPage(0);
      setFirst(0);
      setFilterContitionParams([...tempFilterConditions]);
      params.filters = [...tempFilterConditions];

      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: { ...params, page: 0 },
        },
        ...(activeFiltersList.length > 0 ? { activeFilters: activeFiltersList } : {}),
      });
      setParamsWithFilter(params);
    } else if (!e.removeFilter && Object.keys(e).length > 0) {
      const tempFilterParam = {
        fieldComparisonType: e.fieldComparisonType,
        filterField: e.filterField,
        filterValue: e.filterValue,
      };
      saveFilters(tempFilterParam);
    }
  };

  const onAdvanceFilter = (e) => {
    let currentUrl = null;
    if (!url || url === '' || url == undefined) {
      currentUrl = { asPath: typeof window !== 'undefined' ? window.location.pathname : '' };
    } else {
      currentUrl = url;
    }
    if (Object.keys(e).length > 0) {
      const tempFilterParam = {
        fieldComparisonType: e.fieldComparisonType,
        filterField: e.filterField,
        filterValue: e.filterValue,
      };
      saveFilters(tempFilterParam);
    } else {
      const tempFilterContitionParams = [...filterContitionParams];
      const advanceFilters = Object.values(advanceFilterData);
      const key = filterContitionParams.findIndex((f) => {
        if (advanceFilters.findIndex((a) => a.value === f.filterField) != -1) {
          return true;
        }
        return false;
      });
      tempFilterContitionParams.splice(key, 1);
      setFilterContitionParams(tempFilterContitionParams);
      const params = { ...paramsWithFilter };
      params.filters = [...tempFilterContitionParams];
      setPage(0);
      setFirst(0);
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: currentUrl.asPath,
        userId: user && user.id,
        tableId,
        tablePagingPreference: {
          pagingInfo: params,
          filterParams: {},
          filters: { ...tempFilterContitionParams, page: 0 },
        },
      });
      setParamsWithFilter({ ...params });
    }
  };

  const Template = {
    layout: 'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
    FirstPageLink: (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
          <span className="p-3">First</span>
          <Ripple />
        </button>
      );
    },
    LastPageLink: (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
          <span className="p-3">Last</span>
          <Ripple />
        </button>
      );
    },
    PrevPageLink: (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
          <span className="p-3">Prev</span>
          <Ripple />
        </button>
      );
    },
    NextPageLink: (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
          <span className="p-3">Next</span>
          <Ripple />
        </button>
      );
    },
    PageLinks: (options) => {
      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
  };

  const ImageTemplate = (rowData) => {
    if (rowData.hasOwnProperty('SitePictures') && rowData.SitePictures.length !== 0) {
      const primaryImage = rowData.SitePictures.find((e) => e.isDefault === true);
      return <img src={`${primaryImage.url}`} alt={rowData.name} height="45px" className="list-image cursor-pointer" />;
    } else {
      return <></>;
    }
  };

  const FilterInput = (options) => {
    const headerItem = Object.values(filterData).find((e) => e.headerName == options.field);
    if (headerItem.type === 'like') {
      return (
        <AutoComplete
          value={options.value}
          onChange={(e) => {
            options.filterCallback(e?.value || null);
            onFilterDebounced(e);
          }}
          className="p-column-filter"
          name={options.field}
          completeMethod={autoComplete}
          suggestions={suggestions}
          field="name"
          style={{ minWidth: '50px' }}
        />
      );
    } else if (headerItem.type === 'number') {
      return (
        <>
          <Dropdown
            optionLabel="label"
            value={options.value?.condition}
            options={Options}
            onChange={(e) => {
              options.filterCallback({ ...options.value, condition: e?.value || '' });
              onFilterByNumber({ ...options.value, condition: e?.value || '' }, headerItem);
            }}
            className="p-column-filter"
          />
          <InputNumber
            className="mt-2 p-column-filter"
            value={options.value?.value || null}
            name={options.field}
            onValueChange={(e) => {
              options.filterCallback({ ...options.value, value: e?.value || '' });
              onFilterByNumber({ ...options.value, value: e.value }, headerItem);
            }}
          />
        </>
      );
    } else if (headerItem.type === 'string' && headerItem.hasOwnProperty('array')) {
      return (
        <CascadeSelect
          optionLabel="name"
          optionGroupLabel="name"
          optionGroupChildren={['nested']}
          value={options.value}
          options={getDropDownOptions(headerItem)}
          onChange={(e) => {
            options.filterCallback(e.value.name);
            onFilterByOptions(e.value, headerItem);
          }}
          name={options.field}
          placeholder="Select Options"
        />
      );
    } else if (headerItem.type === 'date') {
      return (
        <>
          <Dropdown
            optionLabel="label"
            value={options.value?.condition}
            options={Options}
            onChange={(e) => {
              options.filterCallback({ ...options.value, condition: e?.value || '' });
              onFilterByDate({ ...options.value, condition: e.value }, headerItem);
            }}
            className="p-column-filter"
          />
          <Calendar
            className="mt-2"
            value={options.value?.value ? new Date(options.value?.value) : null}
            dateFormat={'dd/mm/yy'}
            onChange={(e) => {
              options.filterCallback({ ...options.value, value: e?.value || '' });
              onFilterByDate({ ...options.value, value: e.value }, headerItem);
            }}
          />
        </>
      );
    } else if (headerItem.type === 'boolean') {
      return (
        <>
          <Dropdown
            optionLabel="label"
            value={options.value}
            options={BooleanOptions}
            name={options.field}
            onChange={(e) => {
              options.filterCallback(e.value);
              onFilter(e);
            }}
            className="p-column-filter"
          />
        </>
      );
    } else if (headerItem.type === 'groupDropDown') {
      return (
        <Dropdown
          value={options.value}
          options={getDropDownOptions(headerItem)}
          onChange={(e) => {
            options.filterCallback(e?.value);
            onFilterByOptions({ id: e?.value || '' }, headerItem);
          }}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
        />
      );
    } else {
      return (
        <InputText
          value={options.value == null ? '' : options.value}
          name={options.field}
          onChange={(e) => {
            options.filterCallback(e.target.value);
            onFilterDebounced(e);
          }}
        />
      );
    }
  };

  return (
    <div className="col-lg-12 smart-data-table">
      <div className={hideHeader ? 'block' : 'row row-title white-bg p-sm'}>
        <div className={`${title ? 'smart-header-title dis_block' : 'smart-header-no-title dis_block'}`}>
          {title && <TitleView title={title} t={t} />}
          {Array.isArray(extraHeaderButtons) &&
            extraHeaderButtons.length > 0 &&
            extraHeaderButtons.map((ele, i) => {
              return (
                <div className="pull-right" key={i}>
                  <button className="btn cafm-btn-small" onClick={ele.onClick}>
                    {t(ele.label)}
                  </button>
                </div>
              );
            })}
          <div
            style={customSearchFilter ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' } : {}}
          >
            {customSearchFilter && (
              <CustomTextBox
                key={'all'}
                t={t}
                setCustomFilter={setCustomFilter}
                filterData={searchData}
                oldSelectedList={paramsWithFilter?.filters?.filter((ele) => ele.filterField === 'all') || []}
              />
            )}

            {showAddNew &&
              (addNewItem.isModal ? (
                <AddInModalViewSmall openModal={addNewItem.openModal} title={addNewItem.title} />
              ) : (
                <AddNewItemView
                  route={addNewItem.route}
                  params={addNewItem.params}
                  label={addNewItem.label}
                  t={t}
                  customSearchFilter={customSearchFilter}
                  onClick={addNewItem?.onClick || null}
                />
              ))}
          </div>
          {isArray(deliveryBtn) &&
            deliveryBtn.length > 0 &&
            deliveryBtn.map((e, i) => (
              <AddInModalViewSmall
                key={i}
                openModal={() =>
                  e.openModal(
                    selectedItems.map((i) => i.id),
                    paramsWithFilter,
                  )
                }
                title={e.title}
                disabled={selectedItems.length < 1}
              />
            ))}
          {deliveryBtn && deliveryBtn?.isShow && (
            <AddInModalViewSmall
              openModal={() =>
                deliveryBtn.openModal(
                  selectedItems.map((i) => i.id),
                  paramsWithFilter,
                )
              }
              title={deliveryBtn.title}
              disabled={selectedItems.length < 1}
            />
          )}
          {showQuickAddNew && (
            <div className="wrap-for-max-width pull-right margin_right_20">
              <div className="text-right">
                <button type="button" className="btn oms-btn new" onClick={quickAddNewItem.openModal}>
                  {quickAddNewItem.title}
                </button>
              </div>
            </div>
          )}
          {showStartEndDate && (
            <div>
              <div className="wrap-for-max-width pull-right margin_right_20">
                <div className="text-right">
                  <h3 className="text-right lineHeight">
                    End Date:
                    {startEndDate.endDate}
                  </h3>
                </div>
              </div>
              <div className="wrap-for-max-width pull-right margin_right_20">
                <div className="text-right">
                  <h3 className="text-right lineHeight">
                    Start Date:
                    {startEndDate.startDate}
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`card py-2 flex flex-row flex-wrap  ${
          isCustomFilter || (advanceFilterData && Object.keys(advanceFilterData).length != 0)
            ? 'justify-content-between'
            : 'justify-content-end'
        }`}
      >
        {advanceFilterData && Object.keys(advanceFilterData).length != 0 && (
          <div className="">
            <Button
              type="button"
              icon={`pi ${showAdvancedFilter ? 'pi-filter-slash' : 'pi-filter'}`}
              label={`Advanced Filter`}
              className={`filter-toggle`}
              onClick={() => {
                setShowAdvancedFilter(!showAdvancedFilter);
              }}
            />
          </div>
        )}

        <div className="flex flex-row flex-wrap justify-content-between h-min gap-1" style={{ width: '75%' }}>
          {isCustomFilter && paramsWithFilter.pageSize > 0 && customFilterList.length > 0 && (
            <CustomFilter
              t={t}
              customFilterList={customFilterList}
              customFilterValues={customFilterValues}
              setCustomFilter={setCustomFilter}
              executeResetFns={setDefaultTableColumns}
              paramsWithFilter={paramsWithFilter}
              activeFilter={activeFilter}
            />
          )}
        </div>
        <div className="flex flex-row flex-wrap justify-content-end h-min mt-1">
          <div className={'card flex justify-content-center gap-1 mt-1' + (hideHeader ? ' mr-2' : '')}>
            {columnMoreoptions.length > 0 && (
              <AddMoreColumnOptionsButton
                multipleColumns={multipleColumns}
                setMultipleColumns={setMultipleColumns}
                headers={headers}
                columnMoreoptions={columnMoreoptions}
              />
            )}

            {exportData?.isExport && (
              <DataTableExport exportData={exportData} multipleColumns={multipleColumns} paramList={paramsWithFilter} />
            )}

            {isCustomActions && (
              <TableActionMenu
                permissions={{ isTopDuplicate, isTopEdit }}
                disabled={selectedItems.length === 0}
                otherOptions={otherOptions}
                selectedIds={selectedItems.map((i) => i.id)}
                paramsWithFilter={paramsWithFilter}
                actionsFn={[handleDuplicateMultiJob, handleMultipleEditJob, handleDeleteMultiJob]}
              />
            )}
          </div>
          {!isCustomActions && multiDeleteMode && (
            <Button
              type="button"
              icon="fas fa-1p4x fa-trash"
              label="Delete"
              className={`${selectedItems.length === 0 && 'p-disabled'} filter-toggle mr-4 h-3`}
              onClick={() => {
                handleDeleteMultiJob(
                  selectedItems.map((i) => i.id),
                  paramsWithFilter,
                );
              }}
            />
          )}
          {multiEditReactiveConfig && (
            <Button
              type="button"
              icon={`${selectedItems.length > 0 && 'fa-spin'} fas fa-1p4x fa-cog`}
              label="Reactive Config"
              className={`${selectedItems.length === 0 && 'p-disabled'} filter-toggle mr-4`}
              onClick={() => {
                hamdelMultiEditReactiveConfig(selectedItems.map((i) => i.id));
              }}
            />
          )}

          {multiArchiveMode && (
            <Button
              type="button"
              label={`${showRollBack ? 'Exclude archived' : 'Include all'}`}
              className="filter-toggle mr-4"
              onClick={() => {
                onIncludeAll(!showRollBack);
              }}
            />
          )}
          {showRollBack && (
            <Button
              type="button"
              icon="fas fa-1p4x fa-undo"
              label="Rollback Job"
              className={`${selectedItems.length === 0 && 'p-disabled'} filter-toggle mr-4`}
              onClick={() => {
                handleArchiveMultiJob(
                  selectedItems.map((i) => i.id),
                  paramsWithFilter,
                );
              }}
            />
          )}
          {isFilterAvailable && (
            <Button
              type="button"
              icon={`pi ${filterEnable ? 'pi-filter-slash' : 'pi-filter'}`}
              label={`${filterEnable ? 'Disable Filter' : 'Enable Filter'}`}
              className={`${filterEnable ? 'enabled' : 'disabled'} filter-toggle`}
              onClick={() => {
                if (filterEnable) {
                  clearFilter();
                }
                setFilterEnable(!filterEnable);
              }}
            />
          )}
        </div>
      </div>
      {showAdvancedFilter && (
        <SmartFilter
          onFilter={onAdvanceFilter}
          filterFields={advanceFilterData}
          filterValues={filterValues}
          boolTypes={boolTypes}
          budgetPeriodFilter={budgetPeriodFilter}
          categoryGroupFilter={categoryGroupFilter}
          categoryGroups={categoryGroups}
          store={store}
          t={t}
        />
      )}

      {multipleColumns.length > 0 && (
        <DataTable
          rows={paramsWithFilter.pageSize}
          rowClassName={rowClassNameFn}
          totalRecords={count}
          value={formattedList}
          responsiveLayout="scroll"
          loading={loading}
          onSort={onSort}
          sortField={sortField}
          sortOrder={sortOrder}
          paginatorTemplate={Template}
          onPage={onPageChange}
          first={first}
          size="large"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          paginatorClassName="justify-content-between"
          lazy
          paginator
          stripedRows
          globalFilterFields={['name']}
          onRowDoubleClick={
            isActiveDoubleClick
              ? (e) => {
                  useUuid ? navigateOnClick(e.data.uuid) : navigateOnClick(e.data.id);
                }
              : undefined
          }
          selectionMode={selectionMode}
          selection={selectedItems}
          onSelectionChange={(e) => setSelectedItems(e.value)}
          isDataSelectable={isDataSelectable}
          {...(isColumnFilter && filterEnable ? { filterDisplay: 'row', filters } : {})}
          {...(showRollBack && { showSelectionElement: (e) => (e?.archived === 0 ? '' : true) })}
        >
          {(isCustomActions || multiDeleteMode || multiArchiveMode || multiEditReactiveConfig) && (
            <Column
              selectionMode="multiple"
              headerStyle={{ width: '3rem' }}
              exportable={false}
              style={{ width: '2%' }}
            ></Column>
          )}

          {isArray(multipleColumns) &&
            multipleColumns.length > 0 &&
            multipleColumns.map((h) => {
              const columnConfigItem =
                columnConfig && columnConfig.hasOwnProperty(h.name) ? columnConfig[h.name] : null;
              const columnProps = {
                key: h.name,
                field: h.name,
                header: h.label,
                sortable: h.order === 'DISABLED' ? false : true,
                style: { width: h.width, color: 'black' },
                sortField: h?.orderField || h.name,
              };
              if (h.hasOwnProperty('type') && h.type == 'image') {
                return (
                  <Column
                    key={h.name}
                    field={h.name}
                    header={h.label}
                    sortable={h.order === 'DISABLED' ? false : true}
                    style={{ width: h.width }}
                    body={ImageTemplate}
                  />
                );
              } else if (columnConfigItem?.hasOwnProperty('Body')) {
                return <Column {...columnProps} body={columnConfigItem.Body} />;
              } else {
                return (
                  <Column
                    {...columnProps}
                    showFilterMenu={false}
                    onFilterClear={() => clearFilter(h.name)}
                    {...(filterEnable && { filterElement: FilterInput })}
                    filter={
                      filterEnable && Object.values(filterData).findIndex((i) => i.headerName == h.name) === -1
                        ? false
                        : true
                    }
                  />
                );
              }
            })}
          {showActionColumn && <Column header="Actions" className="actionColumn" body={Menu} style={{ width: '5%' }} />}
        </DataTable>
      )}
      <div style={{ height: '15px' }}></div>
      <Tooltip id="my-tooltip" style={{ fontSize: 13, fontWeight: 'bold', maxWidth: '60%' }} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;
  return {
    user: user.currentuser,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SmartDataTable);

SmartDataTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape()),
  headers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  intableMenu: PropTypes.arrayOf(PropTypes.shape()),
  addNewItem: PropTypes.shape(),
  count: PropTypes.number,
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
  }),
  getListWithSize: PropTypes.func.isRequired,
  getList: PropTypes.func,
  filterData: PropTypes.shape(),
  filterValues: PropTypes.shape(),
  boolTypes: PropTypes.string,
  extended: PropTypes.bool,
  budgetPeriodFilter: PropTypes.bool,
  typeId: PropTypes.string,
  funcs: PropTypes.arrayOf(PropTypes.func),
  useUuid: PropTypes.bool,
  navigateOnClick: PropTypes.func,
  extraParams: PropTypes.shape(),
  taskNavParams: PropTypes.arrayOf(PropTypes.string),
  categoryGroupFilter: PropTypes.bool,
  categoryGroups: PropTypes.arrayOf(PropTypes.shape()),
  navParams: PropTypes.shape(),
  orderField: PropTypes.string,
  showAddNew: PropTypes.bool,
  showIntable: PropTypes.bool,
  hideFilter: PropTypes.bool,
  useSmartFilter: PropTypes.bool,
  title: PropTypes.string,
  t: PropTypes.func.isRequired,
  showQuickAddNew: PropTypes.bool,
  quickAddNewItem: PropTypes.shape(),
  iconOnClick: PropTypes.func,
  multiDeleteMode: PropTypes.bool,
  multiEditReactiveConfig: PropTypes.bool,
  multiArchiveMode: PropTypes.bool,
  handleDeleteMultiJob: PropTypes.func,
  hamdelMultiEditReactiveConfig: PropTypes.func,
  handleArchiveMultiJob: PropTypes.func,
  showStartEndDate: PropTypes.bool,
  startEndDate: PropTypes.shape(),
  checkBoxOnEachRowSetting: PropTypes.shape(),
  showSearchBar: PropTypes.bool,
  loading: PropTypes.bool,
  tableId: PropTypes.string,
  isCustomFilter: PropTypes.bool,
};

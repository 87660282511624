import moment from 'moment';

const calculateHoursByDate = (startAt, departureAt) => {
  const startDate = startAt ? moment(startAt, 'DD/MM/YYYY HH:mm').startOf('m') : undefined;
  const departureDate = departureAt ? moment(departureAt, 'DD/MM/YYYY HH:mm').startOf('m') : undefined;

  let n = '00:00';
  if (startDate && departureDate) {
    const mins = departureDate.diff(startDate, 'minutes');
    const hours = parseInt(mins / 60);
    const minsfinal = parseInt(mins % 60);
    n = hours.toString().padStart(2, '0') + ':' + minsfinal.toString().padStart(2, '0');
  }
  return n;
};

function titleCase(str) {
  try {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  } catch (e) {
    return '';
  }
}

function shortName(str) {
  try {
    let splitStr = str.toUpperCase().split(' ');
    splitStr = splitStr[0].charAt(0) + splitStr[splitStr.length - 1].charAt(0);
    return splitStr;
  } catch (e) {
    return '';
  }
}

const getInitials = (name, limit = 2) =>
  (typeof name === 'string' ? name.match(/\b\w/g)?.join('').toUpperCase().slice(0, limit) : '') || '';

const validateEmail = (email) => {
  if (email && email.length > 0) {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  } else {
    return false;
  }
};

const dateCustomFormat = (date, fromFormat = 'YYYY-MM-DD HH:mm:ss', toFormat = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(date, fromFormat).isValid() ? moment(date, fromFormat).format(toFormat) : null;
};

const formatDaysInFilters = (
  filters = [],
  filds = [],
  dateStartFolmat = 'YYYY-MM-DD 00:00:00',
  dateEndFormat = 'YYYY-MM-DD 23:59:59',
) => {
  return filters.map((e) => {
    if (filds.includes(e.filterField)) {
      const isBetween = e.fieldComparisonType === 'between';
      return {
        fieldComparisonType: 'between',
        filterField: e.filterField,
        filterValue: [
          moment(moment(isBetween ? e.filterValue[0] : e.filterValue).format(dateStartFolmat)).toISOString(),
          moment(moment(isBetween ? e.filterValue[1] : e.filterValue).format(dateEndFormat)).toISOString(),
        ],
      };
    } else {
      return e;
    }
  });
};

function getTableName(paramA, paramB) {
  if (!paramB || typeof paramB !== 'object' || Array.isArray(paramB)) return paramA;
  const values = Object.values(paramB)
    .filter((v) => v != null)
    .join('-');
  return values ? `${paramA}-${values}` : paramA;
}

function mergeArrays(arr1, arr2, key) {
  const mergedArray = [...arr1, ...arr2];
  const uniqueObjectsMap = new Map();
  mergedArray.forEach((item) => {
    uniqueObjectsMap.set(item[key], item);
  });
  const uniqueArray = Array.from(uniqueObjectsMap.values());
  return uniqueArray;
}

const truncateString = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

function adjustToStartOfDay(dateString, isStart = false) {
  let date = moment(dateString);

  if (!dateString.includes(':')) {
    date = isStart ? date.startOf('day') : date.endOf('day');
  }

  return date.format('YYYY-MM-DD HH:mm:ss');
}
const jobAssigningGroup = (data) => {
  if (!Array.isArray(data) || data.length === 0) return [];

  const result = [];

  // Group by UserAssignee id
  const userAssigneeMap = new Map();
  data.forEach((item) => {
    if (item.UserAssignee.length > 0 && item.UserAssignee[0]?.id) {
      const assigneeId = item.UserAssignee[0].id;
      if (!userAssigneeMap.has(assigneeId)) {
        userAssigneeMap.set(assigneeId, {
          templateId: null,
          accountId: item.accountId,
          userAssigneeId: assigneeId,
          supplierCompanyId: null,
          supplierContactId: null,
          jobId: [],
          subject: '',
          template: '',
          SupplierCompany: [],
          SupplierContact: [],
          UserAssignee: item.UserAssignee,
          userAssigneeName: item.UserAssignee[0].fullName,
          emailList: [{ label: item.UserAssignee[0].emailAddress, value: item.UserAssignee[0].emailAddress }],
          emailTo: [{ label: item.UserAssignee[0].emailAddress, value: item.UserAssignee[0].emailAddress }],
        });
      }
      userAssigneeMap.get(assigneeId).jobId.push(item.id);
    }
  });

  // Group by SupplierCompany and SupplierContact id
  const supplierMap = new Map();
  data.forEach((item) => {
    const supplierCompanyId = item.SupplierCompany[0]?.id || null;
    const supplierContactId = item.SupplierContact[0]?.id || null;
    if (supplierCompanyId || supplierContactId) {
      const supplierKey = `${supplierCompanyId || 'null'}-${supplierContactId || 'null'}`;
      if (!supplierMap.has(supplierKey)) {
        supplierMap.set(supplierKey, {
          templateId: null,
          accountId: item.accountId,
          userAssigneeId: null,
          supplierCompanyId: supplierCompanyId,
          supplierContactId: supplierContactId,
          jobId: [],
          subject: '',
          template: '',
          SupplierCompany: item.SupplierCompany,
          SupplierContact: item.SupplierContact,
          UserAssignee: [],
          userAssigneeName: [...item.SupplierCompany.map((s) => s.name), ...item.SupplierContact.map((c) => c.fullName)]
            .filter((name) => name)
            .join(', '),
          emailList: [{ label: item.SupplierContact[0].emailAddress, value: item.SupplierContact[0].emailAddress }],
          emailTo: [{ label: item.SupplierContact[0].emailAddress, value: item.SupplierContact[0].emailAddress }],
        });
      }
      supplierMap.get(supplierKey).jobId.push(item.id);
    }
  });

  // Combine results
  result.push(...userAssigneeMap.values(), ...supplierMap.values());

  return result;
};

export {
  calculateHoursByDate,
  titleCase,
  validateEmail,
  shortName,
  dateCustomFormat,
  formatDaysInFilters,
  getInitials,
  getTableName,
  mergeArrays,
  truncateString,
  adjustToStartOfDay,
  jobAssigningGroup,
};

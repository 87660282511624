import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import {
  LOAD_PPM_JOB,
  LOAD_PPM_JOBS,
  LOAD_DASHBOARD_JOBS,
  LOAD_ALL_PPM_JOBS,
  LOAD_PPM_JOB_COUNT,
  SHOW_LOADER,
  HIDE_LOADER,
} from '../types';
import { api } from '../../lib/api';
import { Router } from '../../../common/routes';
import { handleErrors } from '../TokenActions';
import { showErrorModal, showSuccessModal } from '../ErrorActions';
import userSelectedPreference from '../../lib/userSelectedPreference';
import { getActivityList, loadTimelogItem } from './ActivityActions';
import { getAttachmentListByJobId, setPictures } from './AttachmentActions';
import { getInternalCompanyContactsById, getInternalCompanyFullList } from './InternalCompanyActions';
import { getJobStatusFullList } from './JobStatusActions';
import { getFullUserListbyAccountId } from '../UserActions';
import { formatDaysInFilters } from '../../helpers/customHelper';
import { updateJobActivityItemCalendar, updateJobItemCalendar } from './CalendarActions';
import { getPriorityOptions } from '../../constants/options';

const routePath = '/v2/ppmJob';

export const adjustLimelogs = (e) => {
  e.startAt = moment(e.startAt).isValid() ? moment(e.startAt).format('YYYY-MM-DD HH:mm') : null;
  e.departureAt = moment(e.departureAt).isValid() ? moment(e.departureAt).format('YYYY-MM-DD HH:mm') : null;
};

export const adjustJobs = (job, isExport = false) => {
  const dateformat = isExport === true ? 'YYYY-MM-DD HH:mm:ss' : 'DD/MM/YYYY HH:mm:ss';
  if (job.MaintenanceSchedule && job.MaintenanceSchedule.id) {
    job.maintenanceSchedule = job.MaintenanceSchedule.id;
    job.maintenanceScheduleName = job.MaintenanceSchedule?.name;
  } else {
    job.maintenanceSchedule = null;
    job.maintenanceScheduleName = null;
  }
  job.priorityName = getPriorityOptions().objList?.[job.priority] || '';
  job.priority = job.priority ? parseInt(job.priority) : null;

  job.age = moment(job.createdAt).isValid() ? moment(job.createdAt).fromNow(true) : '';

  job.siteName = !job.siteId || !job.Site ? null : job.Site?.name;
  job.userAssigneeName = !job.UserAssignee ? null : job.UserAssignee?.[0]?.fullName;
  job.statusName = !job.status || !job.Status ? null : job.Status?.name;

  job.dueDate = moment(job.dueDate).isValid() ? moment(job.dueDate).format(dateformat) : null;
  job.visitDate = moment(job.visitDate).isValid() ? moment(job.visitDate).format(dateformat) : null;
  job.closedDate = moment(job.closedDate).isValid() ? moment(job.closedDate).format(dateformat) : null;

  job.createdAt = moment(job.createdAt).isValid() ? moment(job.createdAt).format(dateformat) : null;
  job.updatedAt = moment(job.updatedAt).isValid() ? moment(job.updatedAt).format(dateformat) : null;

  job.issueReportedAt = moment(job.issueReportedAt).isValid() ? moment(job.issueReportedAt).format(dateformat) : null;

  if (!job.scheduleTaskId || !job.ScheduleTask) {
    job.taskLibraryName = null;
  } else {
    job.taskLibraryName = job.ScheduleTask?.TaskLibrary?.name || '';
  }
};

export const loadJob = (job) => ({
  type: LOAD_PPM_JOB,
  payload: job,
});

export const loadJobs = (jobs) => ({
  type: LOAD_PPM_JOBS,
  payload: jobs,
});

export const loadDashboardJobs = (param) => ({
  type: LOAD_DASHBOARD_JOBS,
  payload: param,
});

export const loadAllJobs = (jobs) => ({
  type: LOAD_ALL_PPM_JOBS,
  payload: jobs,
});

export const loadCount = (count) => ({
  type: LOAD_PPM_JOB_COUNT,
  payload: count,
});

export const createJob =
  (job, cb = () => {}) =>
  (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });

    api
      .post(`${routePath}/create`, job)
      .then(async (response) => {
        if (job.jobType == 1) {
          dispatch(loadJob(response.data));
          Router.pushRoute('cafm/admin/ppm_job_profile', { ppm_job_id: response.data.id });
        } else if (job.jobType == 2) {
          dispatch(loadJob(response.data));
          const { pictures } = getState().attachmentUpload;
          const uploads = pictures;
          const multipart = new FormData();
          multipart.append('jobId', response.data.id);

          if (job.attachmentList) {
            for (var i = 0; i < uploads.length; i++) {
              multipart.append(`files[${i}]`, uploads[i].data.data);
            }
          }
          await api.post(`${routePath}/reactive-task-attachment-upload`, multipart, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          Router.pushRoute('cafm/user/reports/reactive_report_all');
        }
        cb(true);
      })
      .catch((error) => {
        cb(false);
        dispatch(showErrorModal(error.response?.data || []));
        handleErrors(error, dispatch, createJob, job);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const updateJob =
  (job, isOnlyUpdate = false, cb = () => {}) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .put(`${routePath}/update`, job)
      .then((response) => {
        if (isOnlyUpdate === false) {
          const newJob = response.data;
          adjustJobs(newJob);
          dispatch(loadJob(newJob));
        }
        if (cb && typeof cb === 'function') cb();
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, updateJob, job);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const updateMultipleJob =
  (job, gridFilter = null, isCalendar = false, cb = () => {}) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .put(`${routePath}/update-ppm-with-activity`, job)
      .then((response) => {
        if (isCalendar) {
          dispatch(updateJobItemCalendar(job, cb));
        } else {
          if (gridFilter) {
            if (gridFilter.jobType === 1) {
              dispatch(getJobListWithSize(gridFilter));
            } else {
              dispatch(getReactiveJobListWithSize(gridFilter));
            }
          } else if (job.isSingle) {
            dispatch(getJobAndActivity(job.id));
          }
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, updateMultipleJob, job);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getJob =
  (id, isFormat = true, cb = () => {}) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    api
      .get(`${routePath}/get-item/${id}`)
      .then((response) => {
        const newJob = response.data;
        isFormat && adjustJobs(newJob);
        dispatch(loadJob(newJob));
        newJob.siteId && cb(newJob.siteId);
        if (
          response.data.JobCustomerMapping &&
          response.data.JobCustomerMapping.length > 0 &&
          response.data.JobCustomerMapping[0]?.InternalCompany?.id
        ) {
          dispatch(getInternalCompanyContactsById(response.data.JobCustomerMapping[0].InternalCompany.id));
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, getJob, id);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const deleteJob = (id, maintenanceScheduleId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .delete(`${routePath}/delete/${id}`)
    .then(() => {
      const item = {
        id,
        statusName: null,
      };
      dispatch(loadJob(item));
      if (maintenanceScheduleId) {
        Router.pushRoute('cafm/admin/schedule_profile', { schedule_id: maintenanceScheduleId });
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, deleteJob, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteMultipleJob = (ids, gridFilter, userId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .put(`${routePath}/deleteMultiple`, { ids: ids.toString(), gridFilter })
    .then((response) => {
      if (response.data.result === 'success') {
        if (gridFilter && gridFilter?.jobType == 2) {
          dispatch(getReactiveJobListWithSize(gridFilter));
        } else if (gridFilter && (gridFilter?.jobType == 1 || gridFilter.maintenanceScheduleId)) {
          dispatch(getJobListWithSize(gridFilter));
        } else if (gridFilter && gridFilter?.type == 'dashboard') {
          const { groupName } = gridFilter;
          delete gridFilter.groupName;
          delete gridFilter.type;
          dispatch(getDashboardJobListWithSize(gridFilter, groupName));
        }
      }
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
        userId,
        isMultipleSelectionReset: true,
      });
    })
    .catch((error) => {
      userSelectedPreference.setUserSelectedPreference({
        currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
        userId,
        isMultipleSelectionReset: true,
      });
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, deleteMultipleJob, ids);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteAsArchived =
  (ids, gridFilter, archived, userId = 0) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });
    api
      .put(`${routePath}/deleteAsArchived`, { ids: ids.toString(), gridFilter, archived: { archived } })
      .then((response) => {
        if (response.data.result === 'success') {
          if (gridFilter && gridFilter.jobType == 2) {
            dispatch(getReactiveJobListWithSize(gridFilter));
          } else if (gridFilter && (gridFilter?.jobType == 1 || gridFilter.maintenanceScheduleId)) {
            dispatch(getJobListWithSize(gridFilter));
          } else if (gridFilter && gridFilter?.type == 'dashboard') {
            const { groupName } = gridFilter;
            delete gridFilter.groupName;
            delete gridFilter.type;
            dispatch(getDashboardJobListWithSize(gridFilter, groupName));
          }
        }
        userSelectedPreference.setUserSelectedPreference({
          currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
          userId,
          isMultipleSelectionReset: true,
        });
      })
      .catch((error) => {
        userSelectedPreference.setUserSelectedPreference({
          currentPageUrl: typeof window !== 'undefined' ? window.location.pathname : '',
          userId,
          isMultipleSelectionReset: true,
        });
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, deleteAsArchived, ids);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getJobFullList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-full-list`, { params })
    .then((response) => {
      dispatch(loadAllJobs(response.data));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getJobFullList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getJobList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params: { ...params, jobType: 1 } })
    .then((response) => {
      const newJobs = response.data;
      newJobs.forEach(adjustJobs);
      dispatch(loadJobs(newJobs));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getJobList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getJobListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  if (params.filters) {
    params.filters = formatDaysInFilters(params.filters, ['visitDate', 'dueDate', 'closedDate']);
  }

  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params: { ...params, jobType: 1 } })
    .then((response) => {
      const newJobs = response.data.rows;
      newJobs.forEach(adjustJobs);
      dispatch(loadJobs(newJobs));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getJobListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getReactiveJobList = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .get(`${routePath}/get-list`, { params: { ...params, jobType: 2 } })
    .then((response) => {
      const newJobs = response.data;
      newJobs.forEach(adjustJobs);
      dispatch(loadJobs(newJobs));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getReactiveJobList, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getReactiveJobListWithSize =
  (params, byPassDates = false) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    if (params.filters) {
      params.filters = formatDaysInFilters(params.filters, [
        ...(byPassDates ? ['visitDate', 'dueDate', 'closedDate', 'createdAt'] : []),
      ]);
    }

    api
      .get(`${routePath}/get-list-with-size-multiple-filter`, { params: { ...params, jobType: 2 } })
      .then((response) => {
        const newJobs = response.data.rows;
        newJobs.forEach(adjustJobs);
        dispatch(loadJobs(newJobs));
        dispatch(loadCount(response.data.count));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, getReactiveJobListWithSize, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getDashboardJobListWithSize =
  (params, groupName = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });

    if (params.filters) {
      params.filters = formatDaysInFilters(params.filters, ['visitDate', 'dueDate', 'closedDate']);
    }
    api
      .get(`${routePath}/get-list-with-size-multiple-filter`, { params })
      .then((response) => {
        const newJobs = response.data.rows;
        newJobs.forEach(adjustJobs);
        dispatch(loadDashboardJobs({ [groupName]: { list: newJobs, count: response.data.count } }));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, getDashboardJobListWithSize, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const duplicateTask = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`${routePath}/duplicate-ppm-with-activity`, params)
    .then((response) => {
      if (params.gridFilter) {
        if (params.gridFilter.jobType === 1) {
          dispatch(getJobListWithSize(params.gridFilter));
        } else {
          dispatch(getReactiveJobListWithSize(params.gridFilter));
        }
      } else if (params.isDetailsDuplicate) {
        Router.pushRoute(`/cafm/user/report/${response.data[0].id}/view`);
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, duplicateTask, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getJobListExport = (params) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${routePath}/get-list-with-size-multiple-filter`, params)
      .then((response) => {
        const newJobs = response.data.rows;
        newJobs.forEach((e) => adjustJobs(e, true));
        resolve(newJobs);
      })
      .catch((error) => {
        resolve([]);
      });
  });
};

export const getJobAndActivity = (id) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/get-item/${id}`)
    .then((response) => {
      const res = response.data;
      adjustJobs(res);
      dispatch(loadJob(res));
      dispatch(getActivityList(res.id, res.jobType));

      if (response.data.Site?.id) {
        dispatch(getJobStatusFullList({ accountId: res.Site.accountId }));
        dispatch(getFullUserListbyAccountId({ accountId: res.Site.accountId }));
        dispatch(getInternalCompanyFullList({ siteId: response.data.Site?.id }));
      }

      if (
        res.JobCustomerMapping &&
        res.JobCustomerMapping.length > 0 &&
        res.JobCustomerMapping[0]?.InternalCompany?.id
      ) {
        dispatch(getInternalCompanyContactsById(res.JobCustomerMapping[0].InternalCompany.id));
      }
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getJobAndActivity, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const sendEmail = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  api
    .post(`/cafm/task/${params.id}/send-email`, params)
    .then((response) => {
      dispatch(
        showSuccessModal([
          {
            message: 'Job Status sent successfully',
          },
        ]),
      );
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, sendEmail, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

const uploadAttachments = (args, getState, dispatch) => {
  return new Promise((resolve, reject) => {
    const { pictures } = getState().attachmentUpload;
    var uploads = pictures;
    var tempData = {
      id: args.id,
    };
    if (uploads && uploads.length > 0) {
      var multipart = new FormData();
      var addedAttachments = [];
      multipart.append('maintenanceScheduleId', args.maintenanceScheduleId || null);
      multipart.append('jobId', args.jobId);
      multipart.append('id', args.id);
      for (var i = 0; i < uploads.length; i++) {
        var fileName = uuidv4();
        +'.' + uploads[i].data.name.split('.').pop();
        multipart.append('file' + i, uploads[i].data.data);
        multipart.append('fileName' + i, fileName);
        addedAttachments.push({ associatedId: args.id, fileName: fileName, friendlyName: uploads[i].data.name });
      }
      api
        .post(`/v2/jobVisitTimeEntry/${args.id}/attachment/upload`, multipart, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(async (response) => {
          tempData.addedAttachments = addedAttachments;
          await saveTaskTimelogAttachments(args.id, tempData, args.jobId, dispatch);
          resolve();
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(showErrorModal(error.response?.data));
          handleErrors(error, dispatch, uploadAttachments, args);
          resolve();
        });
    }
  });
};

function saveTaskTimelogAttachments(id, params, jobId, dispatch) {
  return new Promise((resolve, reject) => {
    api
      .post(`/v2/jobVisitTimeEntry/${id}/attachment/bulk-create`, params)
      .then((response) => {
        resolve();
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, saveTaskTimelogAttachments, params);
        resolve();
      });
  });
}

export const saveJobVisitTimeEntry =
  (params, callback = () => {}) =>
  (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });
    api
      .post(`/v2/jobVisitTimeEntry/create`, params)
      .then(async (response) => {
        if (params.attachmentList) {
          const tempParams = {
            id: response.data.id,
            jobId: params.jobId,
            maintenanceScheduleId: params?.maintenanceScheduleId,
          };
          await uploadAttachments(tempParams, getState, dispatch);
          dispatch(getAttachmentListByJobId(params.jobId));
          dispatch(setPictures([]));
        }

        dispatch(
          showSuccessModal([
            {
              message: params.successMsg ? params.successMsg : 'Time log saved successfully',
            },
          ]),
        );
        dispatch(getJobAndActivity(params.jobId));
        callback();
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, saveJobVisitTimeEntry, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const deleteJobtimeEntry = (id, job) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .delete(`v2/jobVisitTimeEntry/delete/${id}`)
    .then(() => {
      dispatch(getActivityList(job.id, job.jobType));
      dispatch(getAttachmentListByJobId(job.id));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, deleteJobtimeEntry, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getJobtimeEntryById = (jobId, timeLogId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`v2/jobVisitTimeEntry/get-last-item-by-planned-task/${jobId}?timeLogId=${timeLogId}`)
    .then((response) => {
      const res = response.data;
      dispatch(loadTimelogItem(res));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getJobtimeEntryById, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateJobVisitTimeEntry =
  (params, callback = () => {}, isCalender = false) =>
  (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });

    api
      .put(`/v2/jobVisitTimeEntry/update`, params)
      .then(async (response) => {
        if (isCalender) {
          dispatch(updateJobActivityItemCalendar(params));
        } else {
          dispatch(
            showSuccessModal([
              {
                message: params.successMsg ? params.successMsg : 'Time log updated successfully',
              },
            ]),
          );
          dispatch(getJobAndActivity(params.jobId));
          callback();
        }
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, updateJobVisitTimeEntry, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

/**
 * timelog
 */

export const getTimelogListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });

  if (params.filters) {
    params.filters = formatDaysInFilters(params.filters, ['startAt']);
  }

  api
    .get(`${routePath}/timelog/get-list-with-size-multiple-filter`, { params })
    .then((response) => {
      const newJobs = response.data.rows;
      newJobs.forEach(adjustLimelogs);
      dispatch(loadJobs(newJobs));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      console.log('error', error);
      dispatch(showErrorModal(error.response?.data || ''));
      handleErrors(error, dispatch, getTimelogListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getTimelogListExport = (params) => {
  return new Promise((resolve, reject) => {
    if (params.filters) {
      params.filters = formatDaysInFilters(params.filters, ['startAt']);
    }
    api
      .get(`${routePath}/timelog/get-list-with-size-multiple-filter`, params)
      .then((response) => {
        const newJobs = response.data.rows;
        newJobs.forEach(adjustLimelogs);
        resolve(newJobs);
      })
      .catch((error) => {
        resolve([]);
      });
  });
};

export const getDailyScheduleJobListWithSize = (params) => (dispatch, getState) => {
  dispatch({ type: SHOW_LOADER });
  const user = getState().user;

  if (params.filters) {
    params.filters = formatDaysInFilters(params.filters, ['visitDate']);
    if (user?.user?.id) {
      params.filters = [
        ...params.filters,
        {
          fieldComparisonType: '=',
          filterField: 'userAssigneeId',
          filterValue: user.user.id,
        },
      ];
    }
  }

  api
    .get(`${routePath}/get-list-with-size-multiple-filter`, { params: { ...params } })
    .then((response) => {
      const newJobs = response.data.rows;
      newJobs.forEach(adjustJobs);
      dispatch(loadJobs(newJobs));
      dispatch(loadCount(response.data.count));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getDailyScheduleJobListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const sendJObEmailTemplate = (data, next) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${routePath}/email-template/send-email`, data)
    .then((response) => {
      next(response.data);
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, sendJObEmailTemplate, data);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};


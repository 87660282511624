import React, { useRef } from 'react';
import { MultiSelect } from 'primereact/multiselect';
import { getDropDownPTEvent } from '../../helpers/dropdownHelper';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';

export default function AddMoreColumnOptionsButton({
  multipleColumns = [],
  setMultipleColumns,
  headers = [],
  columnMoreoptions = [],
}) {
  const multiSelectRef = useRef(null);

  const openMultiSelect = () => {
    if (multiSelectRef.current) {
      multiSelectRef.current.show(); // Show the dropdown panel
    }
  };
  return (
    <>
      <div className="p-overlay-badge">
        {multipleColumns.length > 0 && (
          <Badge value={multipleColumns.length} severity="primary" className="customFilterBadge" style={{}}></Badge>
        )}
        <Button
          icon={<i className={`pi pi-plus mr-1`} style={{ fontSize: 14, fontWeight: 'bold' }}></i>}
          style={{ width: 'auto', height: '3.5rem', justifyContent: 'center' }}
          severity="Primary"
          aria-label="Filter"
          onClick={openMultiSelect}
          label="Add columns"
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Add more columns"
          data-tooltip-place="top-start"
        />
      </div>
      <MultiSelect
        value={multipleColumns}
        onChange={(e) => setMultipleColumns(e.value)}
        options={[...headers, ...columnMoreoptions]}
        optionLabel="label"
        placeholder="Select Columns"
        className="w-min h-4rem hidden-multiselect absolute"
        id="multiSelectColumn"
        maxSelectedLabels={0}
        selectedItemsLabel={'Add More Columns'}
        pt={getDropDownPTEvent()}
        ref={multiSelectRef}
      />
    </>
  );
}

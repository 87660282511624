import moment from 'moment';
import { api } from '../../lib/api';
import { adjustToStartOfDay } from '../../helpers/customHelper';

const routePath = '/hr';

export const getHrCalendarData = (accountId, dates) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `${routePath}/${accountId}/absences/requests?status=calendar&limit=1000&fromDate=${moment(dates.startDate)
          .startOf('month')
          .format('YYYY-MM-DD HH:mm:ss')}&toDate=${moment(dates.endDate).endOf('month').format('YYYY-MM-DD HH:mm:ss')}`,
      )
      .then((response) => {
        let data = response?.data?.data;
        if (data && data.length > 0) {
          data = data.map((item) => {
            return {
              id: item.id,
              status: item.status,
              absenceType: item?.absenceType,
              companyId: item.companyId,
              StartTime: adjustToStartOfDay(item.fromDateTime, true),
              EndTime: adjustToStartOfDay(item.toDateTime),
              UserAssigneeId: item?.User?.id,
              userAssigneeName: item?.User?.fullName,
              isLeave: true,
              bgColor: item.absenceType?.leaveTypeColor || 'gray',
              isReadonly: true,
            };
          });
        }
        resolve(data);
      })
      .catch((error) => {
        console.log('getHrCalendarData:error', error);
      })
      .then(() => {
        resolve([]);
      });
  });
};

export const getHrCalendarPublicHolidays = (accountId) => {
  return new Promise((resolve, reject) => {
    api
      .get(`${routePath}/${accountId}/absences/settings/reserved-dates/public-holidays`)
      .then((response) => {
        let data = response?.data?.data;
        if (data && data.length > 0) {
          data = data.map((item) => {
            return {
              id: item.id,
              companyId: item.companyId,
              name: item.name,
              StartTime: adjustToStartOfDay(item.fromDate, true),
              EndTime: adjustToStartOfDay(item.toDate),
              isBankHoliday: true,
              isLeave: true,
              bgColor: '#31B0D5',
            };
          });
        }
        resolve(data);
      })
      .catch((error) => {
        console.log('getHrCalendarPublicHolidays:error', error);
      })
      .then(() => {
        resolve([]);
      });
  });
};

import { LOAD_ACTIVITY, SHOW_LOADER, HIDE_LOADER, LOAD_ACTIVITY_ITEM } from '../types';
import { api } from '../../lib/api';
import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';

const routePath = '/v2/audit/log';

const adjustAccount = (account) => {
  account.taskApprovalRequired = account.taskApprovalRequired ? 'Yes' : 'No';
};

export const loadAccount = (list) => ({
  type: LOAD_ACTIVITY,
  payload: list,
});

export const loadTimelogItem = (item) => ({
  type: LOAD_ACTIVITY_ITEM,
  payload: item,
});

export const getActivityList = (jobId, jobType) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  // const url =
  //   jobType === 1
  //     ? `/get-list?rowId=${jobId}&entityType=ppmjob&showForSpecificEntity=false&parentAssociatedId=26&isWithComment=1`
  //     : `/get-list?rowId=${jobId}&entityType=ppmjob&showForSpecificEntity=false&parentAssociatedId=0&isWithComment=1`;

  const url =
    jobType === 1
      ? `/get-list?rowId=${jobId}&entityType=ppmjob&showForSpecificEntity=false&isWithComment=1`
      : `/get-list?rowId=${jobId}&entityType=ppmjob&showForSpecificEntity=false&parentAssociatedId=0&isWithComment=1`;
  api
    .get(`${routePath + url}`)
    .then((response) => {
      const res = response.data;
      dispatch(loadAccount(res));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getActivityList);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getActivityListData = (jobId, jobType, limit = 3) => {
  return new Promise((resolve, reject) => {
    const url =
      jobType === 1
        ? `/get-list?rowId=${jobId}&entityType=ppmjob&showForSpecificEntity=false&parentAssociatedId=26&isWithComment=1&limit=${
            limit || 3
          }`
        : `/get-list?rowId=${jobId}&entityType=ppmjob&showForSpecificEntity=false&parentAssociatedId=0&isWithComment=1&limit=${
            limit || 3
          }`;
    api
      .get(`${routePath + url}`)
      .then((response) => {
        const res = response.data;
        resolve(res);
      })
      .catch((error) => resolve([]));
  });
};

export const getParcelActivityList = (parcelId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  const url = `/get-list?rowId=${parcelId}&entityType=parcel&showForSpecificEntity=false&parentAssociatedId=35`;
  api
    .get(`${routePath + url}`)
    .then((response) => {
      const res = response.data;
      dispatch(loadAccount(res));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getParcelActivityList);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

import React from 'react';

function ClientOnly({ user, children, ...delegated }) {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
    if (window.pendo && user) {
      console.log(user);
      window.pendo.initialize({
        visitor: {
          id: (user.user && user.user.id) || '',
          email: (user.user && user.user.email) || '',
        },
        account: {
          id: (user.currentActiveAccount && user.currentActiveAccount.id) || '',
          name: (user.currentActiveAccount && user.currentActiveAccount.name) || '',
        },
      });
    }
  }, []);
  if (!hasMounted) {
    return null;
  }
  return <div {...delegated}>{children}</div>;
}

export default ClientOnly;

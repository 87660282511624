import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MentionQuill } from '../form/parts';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { getEmailTemplateById } from '../../actions/cafm/AccountActions';
import { EMAIL_TEMPLATE_FIELD_LIST } from '../../constants/options';
import { titleCase, validateEmail } from '../../helpers/customHelper';
import Select from 'react-select';
import { sendJObEmailTemplate } from '../../actions/cafm/JobActions';
import { toast } from 'react-toastify';
function SendEmailModal({ open, emailTemplateList, onModalClose, groupedData }) {
  const dispatch = useDispatch();
  const emailTemplateItem = useSelector((state) => state?.account?.emailTemplateItem);
  const [userList, setUserList] = useState([]);
  const [templateId, setTemplateId] = useState(null);
  const [loader, setLoader] = useState(false);
  const setEmailTemplate = (e) => {
    e.stopPropagation();
    setLoader(true);
    const id = e.value;
    setTemplateId(id);
    dispatch(getEmailTemplateById(id));
  };
  const onChange = (fieldName, value, index) => {
    const updatedList = [...userList];
    updatedList[index][fieldName] = value;
    setUserList(updatedList);
  };
  const onToast = (severity, message) => {
    if (!severity) return;
    toast[severity](message, {
      position: 'top-right',
      autoClose: 3000,
    });
  };
  const onSendEmail = () => {
    if (!templateId) {
      console.error('Template ID is required');
      return;
    }
    let checkEmailPresent = true;
    const newUserList = userList.map((item) => {
      if (!item.emailTo.length) {
        checkEmailPresent = false;
      }
      return {
        templateId: item.templateId,
        accountId: item.accountId,
        userAssigneeId: item.userAssigneeId,
        supplierCompanyId: item.supplierCompanyId,
        supplierContactId: item.supplierContactId,
        jobId: item.jobId,
        jobType: item.jobType,
        subject: item.subject,
        SupplierCompany: item.SupplierCompany,
        SupplierContact: item.SupplierContact,
        UserAssignee: item.UserAssignee,
        userAssigneeName: item.userAssigneeName,
        emailList: item.emailList,
        emailTo: item.emailTo?.flatMap((subItem) => subItem.value) || [],
        template: item.template,
      };
    });
    const payload = [...newUserList];

    if (!checkEmailPresent) {
      const msg = (newUserList?.length > 1 ? 'Every users' : 'User') + ' should have an email address.';
      onToast('warn', msg);
      return;
    }
    dispatch(
      sendJObEmailTemplate(payload, (response) => {
        if (response.success) {
          onToast('success', response.message);
          onModalClose();
        }
      }),
    );
  };
  useEffect(() => {
    if (emailTemplateItem && templateId) {
      const { id, template, subject } = emailTemplateItem;
      setUserList(
        groupedData.map((data) => {
          return { ...data, tempEmailList: data.emailList, templateId: id, template, subject };
        }),
      );
      setLoader(false);
    }
  }, [emailTemplateItem]);
  return (
    <div>
      <Dialog
        header={() => <h3>Send Email</h3>}
        style={{ width: '80rem' }}
        visible={open}
        onHide={() => {
          onModalClose();
          setTemplateId(null);
        }}
        footer={() => (
          <div className="d-flex justify-content-end gap-1">
            <Button label="Cancel" onClick={onModalClose} severity="secondary"></Button>
            <Button
              label={'Confirm and Send Email' + (userList?.length > 1 ? 's' : '')}
              disabled={!templateId}
              onClick={onSendEmail}
            ></Button>
          </div>
        )}
      >
        <div className="col-md-12 p-xs">
          <p style={{ fontSize: 15, marginBottom: 0 }}>Select Template</p>
          <Dropdown
            value={templateId}
            onChange={(e) => setEmailTemplate(e)}
            options={emailTemplateList}
            optionLabel="name"
            optionValue="id"
            className="reAssignClass or-user-dd mt-1 w-full mb-4"
            placeholder="Select Template"
            style={{ height: 38, fontSize: 13 }}
            showClear={false}
            filter
          />
          <div>
            {userList?.length > 0 &&
              templateId &&
              userList.map((item, index) => (
                <Panel className="mb-5" header={() => <h4>{titleCase(item?.userAssigneeName)}</h4>} key={index}>
                  <div className="w-full">
                    <div className="flex flex-column mb-3">
                      <p style={{ fontSize: 15, marginBottom: 0 }}>Email To</p>
                      <Select
                        className="h-4rem"
                        classNamePrefix="drp-react-selct"
                        isClearable={true}
                        isSearchable={true}
                        placeholder={'Add emails'}
                        isMulti={true}
                        value={item.emailTo ?? null}
                        options={item.emailList}
                        onChange={(e) => {
                          onChange('emailTo', e, index);
                          onChange('emailList', item.tempEmailList || [], index);
                        }}
                        onInputChange={(e) => {
                          if (!validateEmail(e)) return;
                          let newEmail = [...item?.emailList];
                          const emailEntry = { label: e, value: e };
                          if (newEmail.length > 1) {
                            newEmail[newEmail.length - 1] = emailEntry;
                          } else {
                            newEmail.push(emailEntry);
                          }
                          onChange('emailList', newEmail, index);
                        }}
                      />
                    </div>
                    <div className="flex flex-column mb-2">
                      <p style={{ fontSize: 15, marginBottom: 0 }}>Subject</p>
                      <InputText
                        value={item.subject}
                        onChange={(e) => onChange('subject', e.target.value, index)}
                        className="h-4rem  p-inputtext-lg"
                        name="subject"
                      />
                    </div>
                    {loader ? (
                      <></>
                    ) : (
                      <div>
                        <p style={{ fontSize: 15, marginBottom: 0 }}>Body</p>
                        <MentionQuill
                          value={item.template}
                          onTextChange={(value) => onChange('template', value, index)}
                          mentionList={EMAIL_TEMPLATE_FIELD_LIST}
                          mentionTrigger={['{{']}
                        />
                      </div>
                    )}
                  </div>
                </Panel>
              ))}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default SendEmailModal;

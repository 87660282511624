import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { withRouter } from 'next/router';
import { withTranslation } from 'next-i18next';
import { ToastContainer } from 'react-toastify';

import initRedux from './initRedux';
import { Header, SideMenu, HamburgerMenu } from '../containers/decorate';
import ErrorModalContainer from '../containers/decorate/ErrorModalContainer';
import LoaderContainer from '../containers/decorate/LoaderContainer';
import ClientOnly from './ClientOnly';
import { ViewContainer } from '../components/styled';
import '../locales/en/currency';

// Gets the display name of a JSX component for dev tools
const getComponentDisplayName = (ChangedComponent) =>
  ChangedComponent.displayName || ChangedComponent.name || 'Unknown';

const loadTokensToStore = (ComposedComponent) => {
  class WithData extends Component {
    static propTypes = {
      router: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
      }).isRequired,
      tokens: PropTypes.shape(),
      t: PropTypes.func,
    };

    static defaultProps = {
      tokens: {},
      t: () => {},
    };

    static displayName = `WithData(${getComponentDisplayName(ComposedComponent)})`;

    constructor(props) {
      super(props);
      const {
        tokens,
        userData,
        colorSettings,
        currentuserpermissions,
        currentuser,
        currentActiveAccount,
        activeSiteId,
        activeSite,
        userPermissions,
        router: { pathname },
      } = props;

      let currentSystem = '';
      if (pathname.split('/')[1] === 'bms') {
        currentSystem = 'bms';
      } else if (pathname.split('/')[1] === 'cafm' && pathname.split('/')[2] && pathname.split('/')[2] === 'admin') {
        currentSystem = 'cafm-admin';
      } else if (pathname.split('/')[1] === 'cafm') {
        currentSystem = 'cafm';
      }
      if (tokens && tokens.accessToken) {
        const initialState = {
          tokens: {
            accessToken: tokens.accessToken,
            refreshToken: tokens.refreshToken,
          },
          user: {
            user: userData,
            colorSettings,
            currentSystem,
            userPermissions: userPermissions,
            currentuserpermissions: userPermissions,
            list: [],
            currentuser,
            currentActiveAccount,
            activeSiteId,
            activeSite,
          },
        };
        this.redux = initRedux(initialState);
      } else {
        this.redux = initRedux();
      }
    }
    render() {
      const { user } = this.redux.getState();
      return (
        <ClientOnly user={user}>
          <Provider store={this.redux}>
            <ViewContainer colorSettings={user.colorSettings}>
              {(this.props.router.pathname.indexOf('auth') > 0 || this.props.router.pathname.indexOf('trial') > 0) && (
                <ComposedComponent {...this.props} store={this.redux} />
              )}

              {this.props.router.pathname.indexOf('auth') < 0 && this.props.router.pathname.indexOf('trial') < 0 && (
                <div className="cms outer-wrapper">
                  <Header {...this.props} colorSettings={user.colorSettings} currentSystem={user.currentSystem} />
                  <div id="wrapper" className="main-wrapper">
                    <SideMenu {...this.props} pathname={this.props.router.pathname} />
                    <div className="oms-gray-bg height100" id="page-wrapper">
                      <div className="wrapper wrapper-content">
                        <ComposedComponent {...this.props} store={this.redux} url={this.props.router} />
                      </div>
                    </div>
                    <HamburgerMenu
                      {...this.props}
                      pathname={this.props.router.pathname}
                      t={this.props.t}
                      userPermissions={this.props.userPermissions.sitePermission}
                    />
                  </div>
                </div>
              )}
              <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
              <ErrorModalContainer t={this.props.t} />
              <LoaderContainer />
            </ViewContainer>
          </Provider>
        </ClientOnly>
      );
    }
  }
  return withTranslation(['common'])(WithData);
};

const withNextData = (ComposedComponent) => withRouter(loadTokensToStore(ComposedComponent));
export default withNextData;

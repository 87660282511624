import {
  LOAD_USER,
  LOAD_USERS,
  LOAD_ACCOUNT_USERS,
  LOAD_COLOR_SETTINGS,
  LOAD_CURRENT_SYSTEM,
  LOAD_USER_PERMISSIONS,
  LOAD_POST_FORGOT_DATA,
  LOAD_VALIDATE_PASSWORD_RESET_TOKEN_DATA,
  LOAD_POST_RESET_DATA,
  LOAD_POST_SIGNUP_DATA,
  LOAD_VALIDATE_SIGNUP_ACTIVATE_TOKEN_DATA,
  LOAD_POST_SIGNUP_ACTIVATE_DATA,
  LOAD_USER_CHECKRIGHTS,
  LOAD_CURRENT_USER,
  LOAD_USER_PRODUCT_LIST,
  LOAD_CURRENT_USER_PERMISSIONS,
  LOAD_ALL_USERS,
  LOAD_USER_COUNT,
  LOAD_NEW_ADDED_USER,
  LOAD_USER_PEFERENCES,
} from '../actions/types';

const INITIAL_STATE = {
  user: null,
  colorSettings: {},
  currentSystem: 'bms',
  userPermissions: [],
  list: [],
  accountUsers: [],

  postForgotData: {},
  validatePasswordResetTokenData: {},
  postResetData: {},
  postSignupData: {},
  validateSignupActivateTokenData: {},
  postSignupActivateData: {},

  userCheckRights: {},
  currentuser: {},
  currentuserpermissions: [],
  userProductList: {},
  all: [],
  count: null,
  newAddedUser: [], //1446/1453
  userPreference: [],
};

const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_USER:
      return { ...state, user: action.payload };
    case LOAD_USERS:
      return { ...state, list: action.payload };
    case LOAD_ACCOUNT_USERS:
      return { ...state, accountUsers: action.payload };
    case LOAD_COLOR_SETTINGS:
      return { ...state, colorSettings: action.payload };
    case LOAD_CURRENT_SYSTEM:
      return { ...state, currentSystem: action.payload };
    case LOAD_USER_PERMISSIONS:
      return { ...state, userPermissions: action.payload };

    case LOAD_POST_FORGOT_DATA:
      return { ...state, postForgotData: action.payload };
    case LOAD_VALIDATE_PASSWORD_RESET_TOKEN_DATA:
      return { ...state, validatePasswordResetTokenData: action.payload };
    case LOAD_POST_RESET_DATA:
      return { ...state, postResetData: action.payload };
    case LOAD_POST_SIGNUP_DATA:
      return { ...state, postSignupData: action.payload };
    case LOAD_VALIDATE_SIGNUP_ACTIVATE_TOKEN_DATA:
      return { ...state, validateSignupActivateTokenData: action.payload };
    case LOAD_POST_SIGNUP_ACTIVATE_DATA:
      return { ...state, postSignupActivateData: action.payload };

    case LOAD_USER_CHECKRIGHTS:
      return { ...state, userCheckRights: action.payload };
    case LOAD_CURRENT_USER:
      return { ...state, currentuser: action.payload };
    case LOAD_CURRENT_USER_PERMISSIONS:
      return { ...state, currentuserpermissions: action.payload };

    case LOAD_USER_PRODUCT_LIST:
      return { ...state, userProductList: action.payload };

    case LOAD_ALL_USERS:
      return { ...state, all: action.payload };
    case LOAD_USER_COUNT:
      return { ...state, count: action.payload };
    case LOAD_NEW_ADDED_USER:
      return { ...state, newAddedUser: action.payload };
    case LOAD_USER_PEFERENCES:
      return { ...state, userPreference: action.payload };
    default:
      return state;
  }
};

export default user;

import moment from 'moment';
import { isArray } from 'lodash';
import { SHOW_LOADER, HIDE_LOADER, LOAD_CALENDAR } from '../types';
import { api } from '../../lib/api';
import { handleErrors } from '../TokenActions';
import { showErrorModal, showSuccessModal } from '../ErrorActions';
import { updateJobVisitTimeEntry, updateMultipleJob } from './JobActions';
import { formatDaysInFilters, getInitials, truncateString } from '../../helpers/customHelper';
import { getPriorityOptions } from '../../constants/options';
import { getHrCalendarData, getHrCalendarPublicHolidays } from './HrActions';

const routePath = '/v2/ppmJob';

export const adjustJobs = (job) => {
  job.priorityName = getPriorityOptions().objList?.[job.priority] || '';
  job.priority = job.priority ? parseInt(job.priority) : null;
  job.description = job.description ? truncateString(job.description || '<></>', 200) : null;
};

export const loadCalendar = (params) => ({
  type: LOAD_CALENDAR,
  payload: params,
});

export const getJobCalendarData =
  (selectedStartDate = null, selectedEndDate = null) =>
  async (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });
    const { startDate, endDate } = await getState().calendar;

    const params = {
      startDate: selectedStartDate ? selectedStartDate : startDate,
      endDate: selectedEndDate ? selectedEndDate : endDate,
    };

    api
      .get(`v2/ppmJob/get-job-schedule-task-list`, { params })
      .then((response) => {
        const data = response.data;
        dispatch(loadCalendar({ listData: data, ...params }));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data || []));
        handleErrors(error, dispatch, getJobCalendarData, startDate);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const getJobCalendarDataWithFilter = (params, accountId) => async (dispatch, getState) => {
  dispatch({ type: SHOW_LOADER });

  const dates = params?.dates || {
    startDate: moment().format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().format('YYYY-MM-DD HH:mm:ss'),
  };

  if (params.filters) {
    params.filters = formatDaysInFilters(params.filters, ['visitDate', 'dueDate', 'closedDate', 'createdAt']);
    delete params.dates;
  }

  api
    .get(`${routePath}/calender/get-list-with-size-multiple-filter`, { params })
    .then(async (response) => {
      const data = response.data;
      data.forEach(adjustJobs);
      const usersWithTasks = data.length > 0 ? [...new Set(data.map((e) => e.UserAssigneeId))] : [];
      
      const timeBlockList = data.filter((e) => e.jobType === 3);
      const hrLeaveData = await getHrCalendarData(accountId, dates);
      const hrPublicHolidaysData = await getHrCalendarPublicHolidays(accountId);

      const users = params.filters.find((e) => e.filterField == 'userAssigneeId');

      let rescheduleJobs = filterDataByDateRanges(
        data,
        [...hrLeaveData, ...timeBlockList],
        hrPublicHolidaysData,
        users?.filterValue || [],
      );
      rescheduleJobs =
        rescheduleJobs.length > 0
          ? rescheduleJobs.map((e) => {
              return {
                ...e,
                isUnassignedOrInternal: e.isInternalUser || e.UserAssigneeId === 'Unassigned' || e.isLeave,
                backgroundColor: e.SiteColor,
                displayName: getInitials(e.SiteName, 5),
              };
            })
          : [];


      dispatch(
        loadCalendar({
          listData: [...data, ...hrLeaveData],
          hrLeaveData,
          hrPublicHolidaysData,
          usersWithTasks,
          ...dates,
          rescheduleJobs,
        }),
      );
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || []));
      handleErrors(error, dispatch, getJobCalendarDataWithFilter, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

function filterDataByDateRanges(data, hrLeaveData, hrPublicHolidaysData, users = []) {
  // Filter and validate ranges
  const leaveRanges = hrLeaveData.filter(
    (range) => moment(range.StartTime).isValid() && moment(range.EndTime).isValid(),
  );

  const publicHolidayRanges = hrPublicHolidaysData.filter(
    (range) => moment(range.StartTime).isValid() && moment(range.EndTime).isValid(),
  );

  // Filter the data array
  return data.filter((item) => {
    const itemStart = moment(item.StartTime);
    const itemEnd = moment(item.EndTime);

    // Check if item's start and end times are valid
    if (!itemStart.isValid() || !itemEnd.isValid() || (users.length > 0 && !users.includes(item.UserAssigneeId))) {
      return false;
    }

    // Check if the item matches leave ranges or public holiday ranges
    const matchesLeaveRange = leaveRanges.some((range) => {
      const rangeStart = moment(range.StartTime);
      const rangeEnd = moment(range.EndTime);

      return (
        item.jobType !== 3 &&
        item.UserAssigneeId === range.UserAssigneeId &&
        itemStart.isSameOrAfter(rangeStart) &&
        itemEnd.isSameOrBefore(rangeEnd)
      );
    });

    const matchesPublicHolidayRange = publicHolidayRanges.some((range) => {
      const rangeStart = moment(range.StartTime);
      const rangeEnd = moment(range.EndTime);

      return itemStart.isSameOrAfter(rangeStart) && itemEnd.isSameOrBefore(rangeEnd);
    });

    return matchesLeaveRange || matchesPublicHolidayRange;
  });
}

export const updateJobCalendar =
  (params, cb = () => {}) =>
  (dispatch, getState) => {
    dispatch(updateMultipleJob(params, null, true, cb));
  };

export const updateJobItemCalendar =
  (params, cb = () => {}) =>
  (dispatch, getState) => {
    cb();
  };

export const getJobTimeEntryCalendarData =
  (selectedStartDate = null, selectedEndDate = null, selectedUser = null) =>
  async (dispatch, getState) => {
    dispatch({ type: SHOW_LOADER });
    const { startDate, endDate } = await getState().calendar;

    const params = {
      startDate: selectedStartDate ? selectedStartDate : startDate,
      endDate: selectedEndDate ? selectedEndDate : endDate,
      ...(selectedUser && isArray(selectedUser) && selectedUser.length > 0 ? { userAssignee: selectedUser } : {}),
    };

    api
      .get(`v2/ppmJob/get-job-schedule-task-activity-list`, { params })
      .then((response) => {
        const data = response.data;
        dispatch(loadCalendar({ listData: data, ...params }));
      })
      .catch((error) => {
        dispatch(showErrorModal(error.response?.data || []));
        handleErrors(error, dispatch, getJobCalendarData, startDate);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const updateJobActivityCalendar = (params) => (dispatch, getState) => {
  dispatch(updateJobVisitTimeEntry(params, null, true));
};

export const updateJobActivityItemCalendar = (params) => (dispatch, getState) => {
  const { listData } = getState().calendar;
  const listIndex = listData.findIndex((e) => e.id == params.id);
  listData[listIndex].UserAssigneeId = params.userAssigneeId;
  listData[listIndex].StartTime = moment(params.startAt).toISOString(true);
  listData[listIndex].EndTime = moment(params.departureAt).toISOString(true);
  listData[listIndex].oldEndTime = moment(params.oldEndTime).toISOString(true);
  listData[listIndex].oldStartTime = moment(params.oldStartTime).toISOString(true);
  dispatch(loadCalendar({ listData: listData }));
};

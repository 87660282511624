import React, { Component, useState, useEffect, memo, useRef } from 'react';
import { connect } from 'react-redux';
import _, { isArray } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import { Toast } from 'primereact/toast';
import { Badge } from 'primereact/badge';
import { DateRangePicker } from 'react-date-range';
import { getDropDownPTEvent } from '../../helpers/dropdownHelper';
import { mergeArrays } from '../../helpers/customHelper';

/**
 * Custom DropDown Component
 * @param {*} param0
 * @returns
 */
const CustomDropDown = ({ setCustomFilter, filterData, oldSelectedList }) => {
  const [state, setState] = useState(null);
  const [selectedItemsLabel, setSelectedItemsLabel] = useState(filterData.name);
  const toast = useRef(null);
  const maxSelectable = filterData.maxSelectable || null;

  useEffect(() => {
    if (filterData.options && oldSelectedList.length > 0) {
      const selected = filterData.options.filter((e) => oldSelectedList[0].filterValue.includes(e.id));
      setStateValue(selected, true);
    }
  }, []);

  const setStateValue = (selectedItems, isLoad = false) => {
    if (maxSelectable && selectedItems?.length > maxSelectable) {
      toast.current.show({
        severity: 'warn',
        summary: 'Oops - Limit Reached',
        detail: filterData.maxSelectableMsg,
        life: 3000,
      });
      return;
    }
    setState(selectedItems);
    setSelectedItemsLabel(getSelectedCountString(filterData.name, selectedItems || [], '', 1));
    !isLoad &&
      setCustomFilter({
        fieldComparisonType: 'in',
        filterField: filterData.filterField,
        filterValue:
          selectedItems?.length > 0
            ? selectedItems.map((e) => e[filterData.optionValue ? filterData.optionValue : 'id'])
            : [],
        removeFilter: !selectedItems || selectedItems?.length == 0 ? true : false,
      });
  };

  return (
    <div className="justify-content-start" style={{ height: '3.6rem' }}>
      <Toast ref={toast} position="top-right" style={{ fontSize: '14px' }} />
      <MultiSelect
        onChange={(e) => {
          setStateValue(e.value);
        }}
        filter
        value={state}
        options={filterData.options}
        optionLabel={filterData.optionLabel}
        {...(filterData.optionValue ? { optionValue: filterData.optionValue } : {})}
        placeholder={filterData.placeholder}
        className="w-full mr-2 customFilterMultiselect"
        style={{
          height: '3.6rem',
          ...(state && state.length > 0 ? { color: '#027BFF', background: '#027BFF26' } : {}),
        }}
        maxSelectedLabels={0}
        selectedItemsLabel={selectedItemsLabel}
        showClear={true}
        pt={getDropDownPTEvent()}
        disabled={filterData.isDisabled || false}
      />
    </div>
  );
};

/**
 * Custom Multi Header DropDown Component
 * @param {*} param0
 * @returns
 */
const CustomMultiHeaderDropDown = ({ setCustomFilter, filterData, oldSelectedList }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemsLabel, setSelectedItemsLabel] = useState(filterData.name);
  const toast = useRef(null);
  const maxSelectable = filterData.maxSelectable || null;

  const generateOptionsHash = () => {
    if (!filterData.options) return '';
    return filterData.options.map((group) => `${group.label}-${group.items.length}`).join('|');
  };

  const optionsHash = generateOptionsHash();

  useEffect(() => {
    if (filterData.options?.length && oldSelectedList?.length) {
      const selected = oldSelectedList[0].filterValue;
      setStateValue(selected, true);
    }
  }, [optionsHash]);

  const setStateValue = (selectedItems = [], isLoad = false, selectedOption = null) => {
    const { filterField, options, name } = filterData;
    selectedItems = selectedItems || [];

    setSelectedItems(selectedItems);

    const allItems = options.flatMap((group) => group.items);
    const firstSelectedItem = allItems.find((item) => item.value === selectedItems[0]);
    const selectedOp = firstSelectedItem ? firstSelectedItem.label : '';

    setSelectedItemsLabel(getSelectedCountString(name, selectedItems, selectedOp, 1));

    if (!isLoad) {
      setCustomFilter({
        fieldComparisonType: 'in',
        filterField,
        filterValue: selectedItems.length > 0 ? selectedItems : [],
        removeFilter: selectedItems.length === 0,
      });
    }
  };

  const onMultiSelectChange = (e) => {
    const newSelectedItems = e.value;

    if (maxSelectable && newSelectedItems?.length > maxSelectable) {
      toast.current.show({
        severity: 'warn',
        summary: 'Oops - Limit Reached',
        detail: filterData.maxSelectableMsg,
        life: 3000,
      });
      return;
    }
    setStateValue(newSelectedItems, false, e?.selectedOption);
  };

  const groupedItemTemplate = (option) => (
    <div className="flex align-items-center">
      <div>{option.label}</div>
    </div>
  );

  return (
    <div className="justify-content-start">
      <Toast ref={toast} position="top-right" style={{ fontSize: '14px' }} contentStyle={{ width: '300px' }} />
      <MultiSelect
        onChange={onMultiSelectChange}
        filter
        value={selectedItems}
        options={filterData.options}
        optionLabel={filterData.optionLabel}
        placeholder={filterData.placeholder}
        className="w-full mr-2 customFilterMultiselect"
        style={{
          height: '3.6rem',
          ...(selectedItems && selectedItems.length > 0 ? { color: '#027BFF', background: '#027BFF26' } : {}),
        }}
        maxSelectedLabels={0}
        selectedItemsLabel={selectedItemsLabel}
        showClear={true}
        optionGroupLabel="label"
        optionGroupChildren="items"
        optionGroupTemplate={groupedItemTemplate}
        pt={getDropDownPTEvent()}
        disabled={filterData.isDisabled || false}
      />
    </div>
  );
};

/**
 * Custom TextBox Component
 * @param {*} param0
 * @returns
 */
export const CustomTextBox = ({ setCustomFilter, filterData, oldSelectedList }) => {
  const [state, setState] = useState(null);
  useEffect(() => {
    oldSelectedList.length > 0 && setState(oldSelectedList[0].filterValue);
  }, [oldSelectedList.length]);

  return (
    <div className="justify-content-start">
      <span className="p-input-icon-right">
        <i className="pi pi-search" style={{ fontSize: 13 }} />
        <InputText
          placeholder={filterData.placeholder}
          value={state || ''}
          onChange={(e) => {
            setState(e.target.value);
            setCustomFilter({
              fieldComparisonType: 'like',
              filterField: filterData.filterField,
              filterValue: e.target.value,
              removeFilter: !e.target.value,
            });
          }}
          className="customFilterTextBox"
          style={{ fontSize: 14 }}
        />
      </span>
    </div>
  );
};

/**
 * Custom Range Calender Component
 */
class CustomRangeCalender extends Component {
  constructor(props) {
    super(props);
    this.date = new Date();
    this.defaultState = {
      selectionRange: [{ startDate: this.date, endDate: this.date, key: 'selection' }],
      visible: false,
      btnTxt: this.props.filterData.placeholder,
    };

    this.state = { ...this.defaultState };
  }

  setFilter = (isLoad = false) => {
    const { startDate, endDate } = this.state.selectionRange[0];
    let btnTxt = this.props.filterData.placeholder;
    let filterValue = null,
      fieldComparisonType = '=';
    if (moment(startDate).isSame(moment(endDate))) {
      btnTxt += `:${moment(startDate).format('DD/MM/YYYY')}`;
      fieldComparisonType = '=';
      filterValue = moment(startDate).format('YYYY-MM-DD');
    } else {
      btnTxt += `:${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
      fieldComparisonType = 'between';
      filterValue = [moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD')];
    }

    !isLoad &&
      this.props.setCustomFilter({
        filterField: this.props.filterData.filterField,
        fieldComparisonType,
        filterValue,
        removeFilter: false,
      });
    this.setState({ visible: false, btnTxt });
  };

  componentDidMount() {
    const { oldSelectedList } = this.props;
    if (oldSelectedList.length > 0) {
      let dateVals = {};
      if (_.isArray(oldSelectedList[0].filterValue)) {
        dateVals = {
          startDate: new Date(oldSelectedList[0].filterValue[0]),
          endDate: new Date(oldSelectedList[0].filterValue[1]),
        };
      } else {
        dateVals = {
          startDate: new Date(oldSelectedList[0].filterValue),
          endDate: new Date(oldSelectedList[0].filterValue),
        };
      }
      this.setState({ selectionRange: [{ ...dateVals, key: 'selection' }] }, () => {
        this.setFilter(true);
      });
    }
  }

  render() {
    const { filterData } = this.props;
    const footerContent = (
      <div className="">
        <Button
          label="No"
          icon="pi pi-times"
          onClick={() => this.setState({ visible: false })}
          className="p-button-text h-3rem"
        />
        <Button label="Yes" icon="pi pi-check" className="h-3rem" onClick={() => this.setFilter()} autoFocus />
      </div>
    );
    const activeStyle =
      this.state.btnTxt != this.props.filterData.placeholder
        ? { color: '#027BFF', background: '#027BFF26' }
        : { background: '#ffffff' };
    return (
      <div className="card flex justify-content-center" style={{ height: '3.6rem', borderRadius: 5 }}>
        <div
          className="flex justify-content-center align-items-center"
          style={{ border: 'solid 1px #d6d4d4', borderRadius: 5, ...activeStyle }}
        >
          <Button
            label={this.state.btnTxt}
            className=" w-auto"
            severity="secondary"
            outlined
            onClick={() => this.setState({ visible: true })}
            style={{
              border: 'none',
              ...(this.state.btnTxt != this.props.filterData.placeholder ? { color: '#027BFF' } : {}),
            }}
          />
          {this.state.btnTxt != this.props.filterData.placeholder && (
            <div
              className="flex align-items-center"
              style={{ width: 24, height: '100%' }}
              onClick={() => {
                this.setState({ ...this.defaultState });
                this.props.setCustomFilter({
                  filterField: this.props.filterData.filterField,
                  fieldComparisonType: '=',
                  filterValue: null,
                  removeFilter: true,
                });
              }}
            >
              <i className="fas fa-times ml-2" style={{ color: '#495057', fontWeight: 100 }}></i>
            </div>
          )}
        </div>
        <Dialog
          header={'Select ' + filterData.placeholder}
          visible={this.state.visible}
          style={{ width: 'auto', height: '448px' }}
          onHide={() => this.setState({ visible: false })}
          footer={footerContent}
        >
          <DateRangePicker
            ranges={this.state.selectionRange}
            onChange={(ranges) => this.setState({ selectionRange: [ranges.selection] })}
          />
        </Dialog>
      </div>
    );
  }
}

const getSelectedCountString = (baseString, selectedList, selectedOption = '', count = 1) => {
  if (selectedList.length > 0) {
    return (
      <span>
        {baseString}: {selectedList[0]?.name || selectedList[0]?.lable || selectedOption} &nbsp;
        {selectedList.length > count && (
          <Badge value={'+' + (selectedList.length - count)} style={{ fontSize: '12px' }} />
        )}
      </span>
    );
  } else {
    return <span>{baseString}</span>;
  }
};

/**
 * MAIN Component
 */
class CustomFilter extends Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      selectedFilterList: [],
      loading: false,
      selectedItemsLabel: 'Filters',
    };

    this.state = { ...this.defaultState };
    this.multiSelectRef = React.createRef();
  }

  componentDidMount() {
    const activeFilter = this.props.activeFilter;
    this.setState({ ...this.getResetStateObj(activeFilter) });
  }

  getResetStateObj = (filters = []) => {
    let oldFilterList = [];
    if (filters.length > 0) {
      oldFilterList = filters.map((e) => e.filterField);
    }
    const selectedList = this.props.customFilterList.filter(
      (e) => e?.isDefault || oldFilterList.includes(e.filterField),
    );
    return {
      selectedFilterList: selectedList,
      selectedItemsLabel: getSelectedCountString(this.defaultState.selectedItemsLabel, selectedList),
    };
  };

  resetFilter = _.debounce((isReset = false) => {
    this.setState(
      {
        loading: true,
        ...(isReset === true && { ...this.defaultState, ...this.getResetStateObj() }),
      },
      () => {
        this.props.setCustomFilter({ removeAll: true, activeFilters: this.state.selectedFilterList });
        this.props.executeResetFns();
        this.setState({ loading: false });
      },
    );
  }, 400);

  setFilterList = (e) => {
    const currentFilters = [...this.state.selectedFilterList];
    const defaultFilters = this.props.customFilterList.filter((e) => e.disabled);
    const newFilters = mergeArrays(
      defaultFilters,
      e.value.length == defaultFilters.length ? [] : e.value,
      'filterField',
    );

    this.setState({
      selectedFilterList: newFilters,
      selectedItemsLabel: getSelectedCountString(this.defaultState.selectedItemsLabel, e.value),
    });
    if (e.value && currentFilters) {
      if (e.value.length === 0) {
        this.props.setCustomFilter({ removeAll: true, activeFilters: [] });
      } else if (e.value.length > 0 && currentFilters.length > 0) {
        this.props.setCustomFilter({ activeFilters: newFilters, addNew: true });
      } else {
        this.props.setCustomFilter({ activeFilters: e.value, addNew: true });
      }
    }
  };

  scfDebounced = _.debounce((e) => this.props.setCustomFilter(e), 400);

  // Function to open the dropdown on clicking the filter icon
  openMultiSelect = () => {
    if (this.multiSelectRef.current) {
      this.multiSelectRef.current.show(); // Show the dropdown panel
    }
  };

  render() {
    const {
      t,
      customFilterList,
      paramsWithFilter: { filters },
      customFilterValues,
    } = this.props;
    const { selectedFilterList, loading, selectedItemsLabel } = this.state;

    return (
      <div className="flex flex-row flex-wrap gap-1 mt-1" id="customFilter">
        <div className="p-overlay-badge">
          {selectedFilterList.length > 0 && (
            <Badge
              value={selectedFilterList.length}
              severity="primary"
              className="customFilterBadge"
              style={{}}
            ></Badge>
          )}
          <Button
            icon={<i className={`pi pi-filter `} style={{ fontSize: 20 }}></i>}
            style={{ width: '3.5rem', height: '3.5rem', justifyContent: 'center' }}
            severity="Primary"
            aria-label="Filter"
            onClick={this.openMultiSelect}
            data-tooltip-id="my-tooltip"
            data-tooltip-content="Select filters"
            data-tooltip-place="top-start"
          />
        </div>

        <MultiSelect
          onChange={(e) => {
            this.setFilterList(e);
          }}
          value={this.state.selectedFilterList}
          options={customFilterList}
          optionLabel="name"
          placeholder="Filters"
          className="w-min h-4rem customFilterMultiselect hidden-multiselect"
          selectedItemsLabel={selectedItemsLabel}
          pt={getDropDownPTEvent()}
          ref={this.multiSelectRef}
        />

        {!loading &&
          selectedFilterList.length > 0 &&
          selectedFilterList.map((e, i) => {
            const oldSelectedList = filters.filter((ele) => ele.filterField === e.filterField);
            if (e.type === 'multiDropdown') {
              const filterValue =
                e.options && isArray(e.options) && e.options.length > 0
                  ? e.options
                  : customFilterValues?.[e.filterField] || [];

              return (
                filterValue.length > 0 && (
                  <CustomDropDown
                    key={e.filterField}
                    t={t}
                    setCustomFilter={this.scfDebounced}
                    filterData={{ ...e, options: filterValue }}
                    oldSelectedList={oldSelectedList}
                  />
                )
              );
            } else if (e.type === 'date') {
              return (
                <CustomRangeCalender
                  key={e.filterField}
                  setCustomFilter={this.scfDebounced}
                  filterData={e}
                  oldSelectedList={oldSelectedList}
                />
              );
            } else if (e.type === 'multiHeadingDropdown') {
              const filterValue = customFilterValues?.[e.filterField] || [];
              return (
                filterValue.length > 0 && (
                  <CustomMultiHeaderDropDown
                    key={e.filterField}
                    t={t}
                    setCustomFilter={this.scfDebounced}
                    filterData={{ ...e, options: filterValue }}
                    oldSelectedList={oldSelectedList}
                  />
                )
              );
            }
          })}

        {selectedFilterList.length > 0 && (
          <Button
            icon="pi pi-times"
            type="button"
            label="Clear"
            severity="secondary"
            raised
            className="mr-1 h-3rem align-self-center"
            onClick={this.resetFilter}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { form, user } = state;
  return {
    formWrapper: form,
    user: user.currentuser,
  };
};

export default memo(connect(mapStateToProps, null)(CustomFilter));

import moment from 'moment';
import {
  LOAD_ACCOUNT,
  SHOW_LOADER,
  HIDE_LOADER,
  LOAD_ALL_EMAIL_TEMPLATES,
  LOAD_EMAIL_TEMPLATES_COUNT,
  LOAD_EMAIL_TEMPLATE,
  LOAD_USER_PEFERENCES,
} from '../types';
import { api } from '../../lib/api';
import { handleErrors } from '../TokenActions';
import { showErrorModal } from '../ErrorActions';
import { getEmailTemplateOptions } from '../../constants/options';
import { Router } from '../../../common/routes';

const routePath = '/v2/account';
const userPreferenceRoutePath = '/v3/user-preference';

const { objList, objTypeList } = getEmailTemplateOptions();

const adjustAccount = (account) => {
  account.taskApprovalRequired = account.taskApprovalRequired ? 'Yes' : 'No';
};

const adjustEmailTemplates = (item) => {
  const type = item.type;
  item.type = objList[item.type];
  item.createdAt = moment(item.createdAt).isValid() ? moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') : null;
  item.updatedAt = moment(item.updatedAt).isValid() ? moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss') : null;
  item.siteName = item?.Site?.name ? item.Site.name : 'NA';
  item.createdBy = objTypeList[type] === 'SYSTEM' ? 'System' : 'User';
};

export const loadAccount = (account) => ({
  type: LOAD_ACCOUNT,
  payload: account,
});

export const loadEmailTemplates = (jobs) => ({
  type: LOAD_ALL_EMAIL_TEMPLATES,
  payload: jobs,
});

export const loadEmailTemplateCount = (count) => ({
  type: LOAD_EMAIL_TEMPLATES_COUNT,
  payload: count,
});

export const loadEmailTemplate = (data) => ({
  type: LOAD_EMAIL_TEMPLATE,
  payload: data,
});

export const loadUserPreference = (data) => ({
  type: LOAD_USER_PEFERENCES,
  payload: data,
});

export const updateAccount = (account) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`${routePath}/cafm/update`, account)
    .then((response) => {
      const newAccount = response.data;
      adjustAccount(newAccount);
      dispatch(loadAccount(newAccount));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateAccount, account);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getAccount = () => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/cafm/get-item`)
    .then((response) => {
      const newAccount = response.data;
      adjustAccount(newAccount);
      dispatch(loadAccount(newAccount));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, getAccount);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getEmailTemplateListWithSize = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${routePath}/email-template/get-list-with-size-multiple-filter`, { params: { ...params } })
    .then((response) => {
      const list = response.data.rows;
      list.forEach(adjustEmailTemplates);
      dispatch(loadEmailTemplates(list));
      dispatch(loadEmailTemplateCount(response.data.count));
    })
    .catch((error) => {
      console.log('error', error);
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getEmailTemplateListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getEmailTemplateById =
  (id, siteId = null, type = null) =>
  (dispatch) => {
    dispatch({ type: SHOW_LOADER });
    api
      .get(`${routePath}/email-template-by-id/${id}`, { params: { type, siteId } })
      .then((response) => {
        const res = response.data;
        if (res) {
          dispatch(loadEmailTemplate(res));
        } else {
          dispatch(showErrorModal([{ message: 'Template not found' }]));
        }
      })
      .catch((error) => {
        console.log('error', error);
        dispatch(showErrorModal(error.response?.data));
        handleErrors(error, dispatch, getEmailTemplateListWithSize, params);
      })
      .then(() => {
        dispatch({ type: HIDE_LOADER });
      });
  };

export const createEmailTemplate = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${routePath}/create-email-templates`, params)
    .then((response) => {
      Router.back();
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, createEmailTemplate, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
export const updateEmailTemplate = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`${routePath}/update-email-templates`, params)
    .then((response) => {
      dispatch(getEmailTemplateById(params.id));
      Router.back();
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateEmailTemplate, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
export const deleteTemplate = (id, params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .delete(`${routePath}/delete-email-template/${id}`)
    .then((response) => {
      dispatch(getEmailTemplateListWithSize(params));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateEmailTemplate, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};
export const deleteMultipleTemplate = (ids, params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`${routePath}/delete-email-templates-multi`, ids)
    .then((response) => {
      dispatch(getEmailTemplateListWithSize(params));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response.data));
      handleErrors(error, dispatch, updateEmailTemplate, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const getUserPreference = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .get(`${userPreferenceRoutePath}/by-user`, { params })
    .then((response) => {
      const res = response.data;
      if (res) {
        dispatch(loadUserPreference(res));
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch(showErrorModal(error.response?.data));
      handleErrors(error, dispatch, getEmailTemplateListWithSize, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const addUserPreference = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .post(`${userPreferenceRoutePath}/create`, params)
    .then(async (response) => {
      dispatch(getUserPreference({ associatedType: params.associatedType }));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || []));
      handleErrors(error, dispatch, addUserPreference, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const updateUserPreference = (params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .put(`${userPreferenceRoutePath}/update`, params)
    .then(async (response) => {
      dispatch(getUserPreference({ associatedType: params.associatedType }));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || []));
      handleErrors(error, dispatch, updateUserPreference, params);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

export const deleteUserPreference = (id, params) => (dispatch) => {
  dispatch({ type: SHOW_LOADER });
  api
    .delete(`${userPreferenceRoutePath}/delete/${id}`)
    .then(async (response) => {
      dispatch(getUserPreference({ associatedType: params.associatedType }));
    })
    .catch((error) => {
      dispatch(showErrorModal(error.response?.data || []));
      handleErrors(error, dispatch, deleteUserPreference, id);
    })
    .then(() => {
      dispatch({ type: HIDE_LOADER });
    });
};

// auth data and tokens
export const LOAD_USER = 'LOAD_USER';
export const LOAD_USERS = 'LOAD_USERS';
export const LOAD_ACCOUNT_USERS = 'LOAD_ACCOUNT_USERS';
export const LOAD_ALL_USERS = 'LOAD_ALL_USERS';
export const LOAD_USER_COUNT = 'LOAD_USER_COUNT';

export const LOAD_CURRENT_USER = 'LOAD_CURRENT_USER';
export const LOAD_CURRENT_USER_PERMISSIONS = 'LOAD_CURRENT_USER_PERMISSIONS';

export const LOAD_ACCESS_TOKEN = 'LOAD_ACCESS_TOKEN';
export const LOAD_REFRESH_TOKEN = 'LOAD_REFRESH_TOKEN';

export const LOAD_COLOR_SETTINGS = 'LOAD_COLOR_SETTINGS';
export const LOAD_CURRENT_SYSTEM = 'LOAD_CURRENT_SYSTEM';
export const LOAD_USER_PERMISSIONS = 'LOAD_USER_PERMISSIONS';
export const LOAD_ROLES_LIST_DATA = 'LOAD_ROLES_LIST_DATA';
export const LOAD_ROLES_FULL_LIST_DATA = 'LOAD_ROLES_FULL_LIST_DATA';
export const LOAD_ROLES_LIST_COUNT = 'LOAD_ROLES_LIST_COUNT';
export const LOAD_ROLE_DATA = 'LOAD_ROLE_DATA';
export const LOAD_ROLE_MEMBERS_LIST_DATA = 'LOAD_ROLE_MEMBERS_LIST_DATA';
export const LOAD_ROLE_MEMBERS_FULL_LIST_DATA = 'LOAD_ROLE_MEMBERS_FULL_LIST_DATA';
export const LOAD_ROLE_MEMBERS_LIST_COUNT = 'LOAD_ROLE_MEMBERS_LIST_COUNT';
export const LOAD_SITE_ROLE_MEMBERS_LIST_DATA = 'LOAD_SITE_ROLE_MEMBERS_LIST_DATA';
export const LOAD_SITE_ROLE_MEMBERS_FULL_LIST_DATA = 'LOAD_SITE_ROLE_MEMBERS_FULL_LIST_DATA';
export const LOAD_SITE_ROLE_MEMBERS_LIST_COUNT = 'LOAD_SITE_ROLE_MEMBERS_LIST_COUNT';
export const LOAD_PERMISSIONS_LIST_DATA = 'LOAD_PERMISSIONS_LIST_DATA';
export const ADD_ROLE_DATA = 'ADD_ROLE_DATA';
export const EDIT_ROLE_DATA = 'EDIT_ROLE_DATA';
export const REMOVE_ROLE_DATA = 'REMOVE_ROLE_DATA';
export const ADD_ROLE_MEMBER_DATA = 'ADD_ROLE_MEMBER_DATA';
export const REMOVE_ROLE_MEMBER_DATA = 'REMOVE_ROLE_MEMBER_DATA';
export const REMOVE_SITE_ROLE_MEMBER_DATA = 'REMOVE_SITE_ROLE_MEMBER_DATA';

export const LOAD_USER_CHECKRIGHTS = 'LOAD_USER_CHECKRIGHTS';

// loading
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

// error handle
export const SHOW_ERROR_MODAL = 'SHOW_ERROR_MODAL';
export const HIDE_ERROR_MODAL = 'HIDE_ERROR_MODAL';

export const SHOW_SUCCESS_MODAL = 'SHOW_SUCCESS_MODAL';
export const HIDE_SUCCESS_MODAL = 'HIDE_SUCCESS_MODAL';

// bms
export const LOAD_BUILDING = 'LOAD_BUILDING';
export const LOAD_BUILDINGS = 'LOAD_BUILDINGS';

export const LOAD_COUNT = 'LOAD_COUNT';

export const LOAD_THRESHOLD = 'LOAD_THRESHOLD';
export const LOAD_THRESHOLDS = 'LOAD_THRESHOLDS';

export const LOAD_BUDGET_CATEGORY = 'LOAD_BUDGET_CATEGORY';
export const LOAD_BUDGET_CATEGORIES = 'LOAD_BUDGET_CATEGORIES';

export const LOAD_BUDGET_CATEGORY_GROUP = 'LOAD_BUDGET_CATEGORY_GROUP';
export const LOAD_BUDGET_CATEGORY_GROUPS = 'LOAD_BUDGET_CATEGORY_GROUPS';

export const LOAD_BUILDING_BUDGET_CATEGORY = 'LOAD_BUILDING_BUDGET_CATEGORY';
export const LOAD_BUILDING_BUDGET_CATEGORIES = 'LOAD_BUILDING_BUDGET_CATEGORIES';

export const LOAD_BUILDING_BUDGET = 'LOAD_BUILDING_BUDGET';
export const LOAD_BUILDING_BUDGETS = 'LOAD_BUILDING_BUDGETS';

export const LOAD_TASK_BUDGET = 'LOAD_TASK_BUDGET';

// cafm
export const LOAD_LOCATION = 'LOAD_LOCATION';
export const LOAD_LOCATIONS = 'LOAD_LOCATIONS';
export const LOAD_PARENT_LOCATIONS = 'LOAD_PARENT_LOCATIONS';
export const LOAD_LOCATION_COUNT = 'LOAD_LOCATION_COUNT';

export const LOAD_ASSET = 'LOAD_ASSET';
export const LOAD_ASSETS = 'LOAD_ASSETS';
export const LOAD_ALL_ASSETS = 'LOAD_ALL_ASSETS';
export const LOAD_ASSET_COUNT = 'LOAD_ASSET_COUNT';

export const SET_PICTURES_ASSET_PICTURES = 'SET_PICTURES_ASSET_PICTURES';
export const SET_REMOVED_ASSET_PICTURES = 'SET_REMOVED_ASSET_PICTURES';

export const SET_PICTURES_SITE_PICTURES = 'SET_PICTURES_SITE_PICTURES';
export const SET_REMOVED_SITE_PICTURES = 'SET_REMOVED_SITE_PICTURES';

export const LOAD_TASK_CATEGORIES = 'LOAD_TASK_CATEGORIES';
export const LOAD_ALL_TASK_CATEGORIES = 'LOAD_ALL_TASK_CATEGORIES';
export const LOAD_TASK_CATEGORY = 'LOAD_TASK_CATEGORY';
export const LOAD_TASK_CATEGORY_COUNT = 'LOAD_TASK_CATEGORY_COUNT';

export const LOAD_TASK = 'LOAD_TASK';
export const LOAD_TASKS = 'LOAD_TASKS';
export const LOAD_ALL_TASKS = 'LOAD_ALL_TASKS';
export const LOAD_TASK_COUNT = 'LOAD_TASK_COUNT';

export const LOAD_SCHEDULE = 'LOAD_SCHEDULE';
export const LOAD_SCHEDULES = 'LOAD_SCHEDULES';
export const LOAD_ALL_SCHEDULES = 'LOAD_ALL_SCHEDULES';
export const LOAD_SCHEDULE_COUNT = 'LOAD_SCHEDULE_COUNT';

export const LOAD_INTERNAL_COMPANY = 'LOAD_INTERNAL_COMPANY';
export const LOAD_INTERNAL_COMPANIES = 'LOAD_INTERNAL_COMPANIES';
export const LOAD_ALL_INTERNAL_COMPANIES = 'LOAD_ALL_INTERNAL_COMPANIES';
export const LOAD_ALL_INTERNAL_COMPANIE_CONTACTS = 'LOAD_ALL_INTERNAL_COMPANIE_CONTACTS';
export const LOAD_ALL_INTERNAL_COMPANIE_CONTACTS_BY_ID = 'LOAD_ALL_INTERNAL_COMPANIE_CONTACTS_BY_ID';
export const LOAD_INTERNAL_COMPANY_COUNT = 'LOAD_INTERNAL_COMPANY_COUNT';

export const LOAD_SITE = 'LOAD_SITE';
export const LOAD_SITES = 'LOAD_SITES';

export const LOAD_MANUFACTURER = 'LOAD_MANUFACTURER';
export const LOAD_MANUFACTURERS = 'LOAD_MANUFACTURERS';
export const LOAD_ALL_MANUFACTURERS = 'LOAD_ALL_MANUFACTURERS';
export const LOAD_MANUFACTURER_COUNT = 'LOAD_MANUFACTURER_COUNT';

export const LOAD_MODEL = 'LOAD_MODEL';
export const LOAD_MODELS = 'LOAD_MODELS';
export const LOAD_MODEL_COUNT = 'LOAD_MODEL_COUNT';

export const LOAD_SFG20_ITEM = 'LOAD_SFG20_ITEM';
export const LOAD_SFG20_LIST = 'LOAD_SFG20_LIST';

export const LOAD_PPM_JOB = 'LOAD_PPM_JOB';
export const LOAD_PPM_JOBS = 'LOAD_PPM_JOBS';
export const LOAD_ALL_PPM_JOBS = 'LOAD_ALL_PPM_JOBS';
export const LOAD_PPM_JOB_COUNT = 'LOAD_PPM_JOB_COUNT';
export const LOAD_DASHBOARD_JOBS = 'LOAD_DASHBOARD_JOBS';

export const LOAD_PARCEL = 'LOAD_PARCEL';
export const LOAD_PARCELS = 'LOAD_PARCELS';
export const LOAD_ALL_PARCELS = 'LOAD_ALL_PARCELS';
export const LOAD_PARCEL_COUNT = 'LOAD_PARCEL_COUNT';
export const SET_SLA_TIME = 'SET_SLA_TIME';

export const LOAD_JOB_STATUS = 'LOAD_JOB_STATUS';
export const LOAD_JOB_STATUSES = 'LOAD_JOB_STATUSES';
export const LOAD_ALL_JOB_STATUSES = 'LOAD_ALL_JOB_STATUSES';

export const LOAD_PROBLEM_CATEGORY = 'LOAD_PROBLEM_CATEGORY';
export const LOAD_PROBLEM_CATEGORIES = 'LOAD_PROBLEM_CATEGORIES';
export const LOAD_ALL_PROBLEM_CATEGORIES = 'LOAD_ALL_PROBLEM_CATEGORIES';
export const LOAD_PROBLEM_CATEGORIES_COUNT = 'LOAD_PROBLEM_CATEGORIES_COUNT';
export const REMOVE_PROBLEM_CATEGORY = 'REMOVE_PROBLEM_CATEGORY';
export const CREATE_PROBLEM_CATEGORY = 'CREATE_PROBLEM_CATEGORY';

export const LOAD_PROBLEM_TOPIC = 'LOAD_PROBLEM_TOPIC';
export const LOAD_PROBLEM_TOPICS = 'LOAD_PROBLEM_TOPICS';
export const LOAD_ALL_PROBLEM_TOPICS = 'LOAD_ALL_PROBLEM_TOPICS';
export const LOAD_PROBLEM_TOPICS_COUNT = 'LOAD_PROBLEM_TOPICS_COUNT';
export const REMOVE_PROBLEM_TOPIC = 'REMOVE_PROBLEM_TOPIC';
export const CREATE_PROBLEM_TOPIC = 'CREATE_PROBLEM_TOPIC';

export const LOAD_ALL_TRACKERS = 'LOAD_ALL_TRACKERS';

export const LOAD_ALL_ENUMERATIONS = 'LOAD_ALL_ENUMERATIONS';
export const LOAD_ACTIVE_ENUMERATIONS = 'LOAD_ACTIVE_ENUMERATIONS';

export const LOAD_ACTIVITY = 'LOAD_ACTIVITY';
export const LOAD_ACTIVITY_ITEM = 'LOAD_ACTIVITY_ITEM';

export const LOAD_CUSTOMER_LIST = 'LOAD_CUSTOMER_LIST';
export const LOAD_CUSTOMER_CONTACT_LIST = 'LOAD_CUSTOMER_CONTACT_LIST';
export const LOAD_CUSTOMER_ADDRESS_LIST = 'LOAD_CUSTOMER_ADDRESS_LIST';

export const LOAD_POST_FORGOT_DATA = 'LOAD_POST_FORGOT_DATA';
export const LOAD_VALIDATE_PASSWORD_RESET_TOKEN_DATA = 'LOAD_VALIDATE_PASSWORD_RESET_TOKEN_DATA';
export const LOAD_POST_RESET_DATA = 'LOAD_POST_RESET_DATA';
export const LOAD_POST_SIGNUP_DATA = 'LOAD_POST_SIGNUP_DATA';
export const LOAD_VALIDATE_SIGNUP_ACTIVATE_TOKEN_DATA = 'LOAD_VALIDATE_SIGNUP_ACTIVATE_TOKEN_DATA';
export const LOAD_POST_SIGNUP_ACTIVATE_DATA = 'LOAD_POST_SIGNUP_ACTIVATE_DATA';

export const LOAD_CONDITION = 'LOAD_CONDITION';
export const LOAD_CONDITIONS = 'LOAD_CONDITIONS';

export const LOAD_LOOKUPVALUE = 'LOAD_LOOKUPVALUE';
export const LOAD_LOOKUPVALUES = 'LOAD_LOOKUPVALUES';
export const LOAD_LOOKUPVALUE_COUNT = 'LOAD_LOOKUPVALUE_COUNT';

export const LOAD_ACCOUNT_FULL_DATA = 'LOAD_ACCOUNT_FULL_DATA';
export const LOAD_ACCOUNT_SUBSCRIPTION_DATA = 'LOAD_ACCOUNT_SUBSCRIPTION_DATA';
export const LOAD_ACCOUNT_SUBSCRIPTION_PRODUCT_LIST = 'LOAD_ACCOUNT_SUBSCRIPTION_PRODUCT_LIST';
export const LOAD_ACCOUNT_BILLINGS = 'LOAD_ACCOUNT_BILLINGS';
export const LOAD_ACCOUNT_BILLINGS_COUNT = 'LOAD_ACCOUNT_BILLINGS_COUNT';
export const LOAD_ACCOUNT_ACTIVE_PAYMENTMETHOD_DATA = 'LOAD_ACCOUNT_ACTIVE_PAYMENTMETHOD_DATA';

export const LOAD_SUBSCRIPTION_PRICE_CALCULATTION_SUMMARY = 'LOAD_SUBSCRIPTION_PRICE_CALCULATTION_SUMMARY';
export const LOAD_PLAN_SKU_DURATION = 'LOAD_PLAN_SKU_DURATION';
export const LOAD_APPLICATION_LIST_FOR_PURCHASE = 'LOAD_APPLICATION_LIST_FOR_PURCHASE';

export const LOAD_USER_PRODUCT_LIST = 'LOAD_USER_PRODUCT_LIST';

export const LOAD_NOTIFICATION = 'LOAD_NOTIFICATION';
export const LOAD_NOTIFICATIONS = 'LOAD_NOTIFICATIONS';
export const LOAD_ALL_NOTIFICATIONS = 'LOAD_ALL_NOTIFICATIONS';
export const LOAD_NOTIFICATION_COUNT = 'LOAD_NOTIFICATION_COUNT';

export const LOAD_TAGS = 'LOAD_TAGS';
export const LOAD_LOOKUPVALUES_TAGS = 'LOAD_LOOKUPVALUES_TAGS';

export const LOAD_APPROVER = 'LOAD_APPROVER';
export const LOAD_APPROVERS = 'LOAD_APPROVERS';
export const LOAD_APPROVER_COUNT = 'LOAD_APPROVER_COUNT';
export const LOAD_SITE_APPROVERS = 'LOAD_SITE_APPROVERS';

export const LOAD_LOGS = 'LOAD_LOGS';
export const LOAD_EMAIL_DELIVERY = 'LOAD_EMAIL_DELIVERY';
export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const LOAD_ENTITLED_ACCOUNT_APPLICATION_FEATURE_LIST = 'LOAD_ENTITLED_ACCOUNT_APPLICATION_FEATURE_LIST';
export const LOAD_ALL_EMAIL_TEMPLATES = 'LOAD_ALL_EMAIL_TEMPLATES';
export const LOAD_EMAIL_TEMPLATES_COUNT = 'LOAD_EMAIL_TEMPLATES_COUNT';
export const LOAD_EMAIL_TEMPLATE = 'LOAD_EMAIL_TEMPLATE';

export const LOAD_ROLES_LIST_USER_NOT_ADDED = 'LOAD_ROLES_LIST_USER_NOT_ADDED';

export const LOAD_ASSET_NOTE = 'LOAD_ASSET_NOTE';
export const LOAD_ALL_ASSET_NOTES = 'LOAD_ALL_ASSET_NOTES';

export const LOAD_TASK_COMPLIANCES = 'LOAD_TASK_COMPLIANCES';
export const LOAD_ALL_TASK_COMPLIANCE = 'LOAD_ALL_TASK_COMPLIANCE';
export const LOAD_TASK_COMPLIANCE = 'LOAD_TASK_COMPLIANCE';
export const LOAD_TASK_COMPLIANCE_COUNT = 'LOAD_TASK_COMPLIANCE_COUNT';
export const LOAD_PARENT_TASK_COMPLIANCES = 'LOAD_PARENT_TASK_COMPLIANCES';
export const LOAD_ALL_CUSTOMIZED_PARENT_CHILD_TASK_COMPLIANCE = 'LOAD_ALL_CUSTOMIZED_PARENT_CHILD_TASK_COMPLIANCE';
export const LOAD_TASK_COMPLIANCE_PARENT_DELETE = 'LOAD_TASK_COMPLIANCE_PARENT_DELETE';
export const LOAD_CHILD_LOCATIONS = 'LOAD_CHILD_LOCATIONS';
export const LOAD_CHILD_LOCATION_COUNT = 'LOAD_CHILD_LOCATION_COUNT';
export const LOAD_CHILD_LOCATION = 'LOAD_CHILD_LOCATION';
export const LOAD_ONLY_PARENT_LOCATIONS = 'LOAD_ONLY_PARENT_LOCATIONS';
export const LOAD_CHILD_TASK_COMPLIANCES = 'LOAD_CHILD_TASK_COMPLIANCES';
export const LOAD_CHILD_TASK_COMPLIANCE_COUNT = 'LOAD_CHILD_TASK_COMPLIANCE_COUNT';
export const LOAD_CHILD_TASK_COMPLIANCE = 'LOAD_CHILD_TASK_COMPLIANCE';
export const LOAD_ONLY_PARENT_TASK_COMPLIANCES = 'LOAD_ONLY_PARENT_TASK_COMPLIANCES';
//1446/1453
export const LOAD_ASSETSURVEY = 'LOAD_ASSETSURVEY';
export const LOAD_ASSETSURVEYS = 'LOAD_ASSETSURVEYS';
export const LOAD_ALL_ASSETSURVEYS = 'LOAD_ALL_ASSETSURVEYS';
export const LOAD_ASSETSURVEY_COUNT = 'LOAD_ASSETSURVEY_COUNT';
export const LOAD_NEW_ADDED_USER = 'LOAD_NEW_ADDED_USER';
export const LOAD_USER_PEFERENCES = 'LOAD_USER_PEFERENCES';
export const SET_ADD_ATTACHMENT_UPLOAD = 'SET_ADD_ATTACHMENT_UPLOAD'; //1449
export const SET_ADD_MULTIPLE_ATTACHMENT_UPLOAD = 'SET_ADD_MULTIPLE_ATTACHMENT_UPLOAD';
export const SET_REMOVE_ATTACHMENT_UPLOAD = 'SET_REMOVE_ATTACHMENT_UPLOAD'; //1449
export const SET_ADD_ATTACHMENT_UPLOAD_CUSTOM = 'SET_ADD_ATTACHMENT_UPLOAD_CUSTOM';

export const LOAD_ASSETS_REVIEW = 'LOAD_ASSETS_REVIEW';
export const UPDATE_ASSETS_REVIEW = 'UPDATE_ASSETS_REVIEW';
export const COMPLETE_ASSETS_REVIEW = 'COMPLETE_ASSETS_REVIEW';

export const LOAD_MAINTENANCE_SCHEDULE_ATTACHMENTS = 'LOAD_MAINTENANCE_SCHEDULE_ATTACHMENTS';
export const LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT_COUNT = 'LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT_COUNT';
export const LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT = 'LOAD_MAINTENANCE_SCHEDULE_ATTACHMENT';

export const LOAD_ASSET_GROUP = 'LOAD_ASSET_GROUP';
export const LOAD_ASSET_GROUPS = 'LOAD_ASSET_GROUPS';
export const LOAD_ALL_ASSET_GROUPS = 'LOAD_ALL_ASSET_GROUPS';
export const LOAD_ASSET_GROUP_COUNT = 'LOAD_ASSET_GROUP_COUNT';

export const LOAD_SCHEDULE_ASSET_GROUP = 'LOAD_SCHEDULE_ASSET_GROUP';
export const LOAD_SCHEDULE_ASSET_GROUPS = 'LOAD_SCHEDULE_ASSET_GROUPS';
export const LOAD_ALL_SCHEDULE_ASSET_GROUPS = 'LOAD_ALL_SCHEDULE_ASSET_GROUPS';
export const LOAD_SCHEDULE_ASSET_GROUP_COUNT = 'LOAD_SCHEDULE_ASSET_GROUP_COUNT';

export const LOAD_ASSETS_ON_MOVE_FROM_ASSET_GROUP_PROFILE = 'LOAD_ASSETS_ON_MOVE_FROM_ASSET_GROUP_PROFILE';

export const LOAD_SCHEDULE_TASK = 'LOAD_SCHEDULE_TASK';
export const LOAD_SCHEDULE_TASKS = 'LOAD_SCHEDULE_TASKS';
export const LOAD_ALL_SCHEDULE_TASKS = 'LOAD_ALL_SCHEDULE_TASKS';
export const LOAD_SCHEDULE_TASK_COUNT = 'LOAD_SCHEDULE_TASK_COUNT';

export const LOAD_SCHEDULE_WITH_TASK = 'LOAD_SCHEDULE_WITH_TASK';
export const LOAD_SCHEDULETASK_COUNT_BY_MAINTENANCESCHEDULE = 'LOAD_SCHEDULETASK_COUNT_BY_MAINTENANCESCHEDULE';
export const LOAD_SCHEDULE_DELETE_POPUP_ON = 'LOAD_SCHEDULE_DELETE_POPUP_ON';
export const LOAD_SCHEDULE_DELETE_POPUP_OFF = 'LOAD_SCHEDULE_DELETE_POPUP_OFF';
export const REMOVE_SCHEDULE = 'REMOVE_SCHEDULE';

export const LOAD_ALL_ASSETS_LINKED_WITH_ASSET_GROUP = 'LOAD_ALL_ASSETS_LINKED_WITH_ASSET_GROUP';
export const LOAD_ALL_ASSETS_LINKED_WITH_MAINTENANCE_SCHEDULE = 'LOAD_ALL_ASSETS_LINKED_WITH_MAINTENANCE_SCHEDULE';
export const LOAD_ALL_INTERNAL_AND_EXTERNAL = 'LOAD_ALL_INTERNAL_AND_EXTERNAL';

export const LOAD_USER_REPORT = 'LOAD_USER_REPORT';
export const LOAD_USER_REPORT_CELL_DATA = 'LOAD_USER_REPORT_CELL_DATA';

export const LOAD_CALENDAR = 'LOAD_CALENDAR';

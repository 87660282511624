import { useEffect, useRef } from 'react';
import { Tooltip } from 'react-tooltip';
import 'quill/dist/quill.snow.css';

const MentionQuill = ({
  placeholder,
  readOnly = false,
  value,
  bounds,
  onTextChange = () => {},
  mentionList = [],
  mentionTrigger = ['#'],
}) => {
  let Quill;
  let editorRef = useRef(null);
  let list;
  const toolTipinfoMap = new Map();
  // Styles for tooltip and content
  const tooltipStyle = {
    padding: 10,
    width: 200,
    fontSize: 13,
    background: '#d3e1eb',
    color: '#000000',
    borderRadius: 10,
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    zIndex: '99999',
  };

  const loadEditor = async () => {
    const { default: QuillImport } = await import('quill');
    const { Mention, MentionBlot } = await import('quill-mention');
    const toolbar = [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ];

    const mentionListHandler = (searchTerm, renderList, mentionChar) => {
      // The mentioned list can come with two types of value objects and arrays, where objects will have key and value pairs.
      // where key will be one of the special characters and value will be its particular list and
      // array is for all types of mention characters.
      if (Array.isArray(mentionList)) {
        list = mentionList;
      } else {
        list = mentionList[mentionChar];
      }

      if (mentionChar === '{{') {
        list = list.map((item) => {
          return { ...item, value: item?.value + '}}', label: item?.label ? item?.label : item?.value };
        });
      }

      //after the mention trigger symbol the text will appear in searchTerm, e.g. mentionList = [{id:1, value:'John Doe'}]
      if (searchTerm.length === 0) {
        renderList(list, searchTerm);
      } else {
        const matches = list.filter((item) => item.value.toLowerCase().includes(searchTerm.toLowerCase()));
        renderList(matches, searchTerm);
      }

      //setting values of tooltip into map in key(id) & value(tooltip) pair for later need,
      //tooltip in mention list can come in string or object according to the type we are performming operations.
      list.forEach((mention) => mention.tooltip && toolTipinfoMap.set(mention?.id, mention.tooltip));
    };

    const mention = {
      allowedChars: /^[A-Za-z\s]*$/,
      mentionDenotationChars: mentionTrigger,
      source: mentionListHandler,
      renderItem: function (item) {
        return item.label ?? item.value;
      },
    };

    Quill = QuillImport;

    if (Quill) {
      Quill.register({ 'blots/mention': MentionBlot, 'modules/mention': Mention });
    }

    Quill = new Quill(editorRef.current, {
      theme: 'snow',
      placeholder,
      readOnly,
      modules: {
        toolbar,
        mention,
      },
      bounds,
    });

    // If a value is present, it will be set into the editor
    if (value) {
      Quill.clipboard.dangerouslyPasteHTML(value);
      // const length = Quill.getLength();
      // Quill.setSelection(length - 1, 0); //to set cursor at the last
    }

    //to un-focus editor
    Quill.blur();

    //for on-change operations
    Quill.on('text-change', () => {
      // const content = Quill.root.innerHTML;

      //extracting all mention class name elements
      const mentionAll = document.querySelectorAll('.mention');
      if (mentionAll.length > 0) {
        mentionAll.forEach((node) => {
          //if mention element's had data-tooltip-id or not
          const isAttrPresent = node.getAttribute('data-tooltip-id') ? true : false;
          const id = Number(node.getAttribute('data-id'));

          //getting values from the map where we put the key value pairs of ID and tooltip information
          const toolTipInfo = toolTipinfoMap.get(id);

          //If the data-tooltip-id attribute is not present and toolTipInfo is present, then it will be added to those elements.
          if (!isAttrPresent && toolTipInfo) {
            node.setAttribute('data-tooltip-id', 'my-tooltip');

            let formattedValue = '';

            if (typeof toolTipInfo !== 'object') {
              formattedValue = toolTipInfo;
            } else {
              for (const key in toolTipInfo) {
                formattedValue += toolTipInfo[key] + '<br/>';
              }
            }

            node.setAttribute('data-tooltip-html', `<div>${formattedValue}</div>`);
          }
        });
      }

      const content = Quill.getSemanticHTML();
      onTextChange(content);
    });
  };

  useEffect(() => {
    //For client side redering, it will call the function and register the mention package in Quill, then create the instance of Quill.
    if (typeof window !== 'undefined' && editorRef.current) {
      loadEditor();
    }
    return () => {
      Quill.off('text-change');
    };
  }, []);

  return (
    <>
      <div ref={editorRef} style={{ height: '300px' }} />
      <Tooltip id="my-tooltip" delayShow={500} style={tooltipStyle} />
    </>
  );
};

export default MentionQuill;
